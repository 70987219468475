/*--- Links --- */
@import "fonts";
@import "colors";
@import "numbers";

@media (max-width: 1200px) {
  .survey-wrapper {
    .surveyDescription {
      h5 {
        width: $per100;
        margin-bottom: $num15;
      }

      .survey-timings.float-end {
        float: none !important;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {}

@media (min-width: 992px) and (max-width: 1400px) {}

@media (min-width: 768px) and (max-width: 991px) {}

@media (max-width: 1199.98px) {
  .registration_note {
    padding: $num10 $num0 $num10 $num12;

    .float-start {
      width: $per100;
    }

    .float-end {
      float: left !important;
      margin-top: $num10;
      font-size: $num11;
    }
  }

  .login-reg-main,
  .page-not-found-main {
    padding: $num90 $num0 $num30;
  }

  .container-fluid {
    padding: $num0 $num12;
  }

  .container {
    max-width: $per100;
    padding: $num0 $num12;
  }

  header {
    .user-dropdown {
      .dropdown-menu[data-bs-popper] {
        margin-top: $num0;
      }
    }
  }

  .settings-main {
    .settings-box {
      padding: $num15;
      margin-bottom: $num10;
    }

    .setting-description {
      .setting-info {
        .settings-input-text {
          .answer_block {
            margin-top: $num0;
          }
        }
      }

      &>.titile-line,
      &>p {
        width: calc(#{$per100} - #{$num55});
      }
    }
  }

  .survey-wrapper {
    .right-panel {
      form {
        .row.drag-drop-area {
          padding: $num15 !important;

          .p-4 {
            padding: $num15 !important;
          }
        }
      }
    }
  }

  .profile-main {
    .left-tab-content {
      .tab-content {
        .personal-details-form {
          padding: $num15;
        }

        .primary-contact-info {
          padding: $num15;
        }

        .organization-list {
          padding: $num15;
        }
      }
    }
  }

  .edit-organization {

    .edit-org-form,
    .organization-primary,
    .card-history-form {
      padding: $num15;
    }
  }

  .survey-controlsettings-main {
    .survey-wrapper {
      .right-panel {
        .fieldbox-white {
          padding: $num15;
          margin-bottom: $num10;
        }
      }
    }
  }

  .add-question-main {
    .add-question-form {
      .question-type-box {
        padding: $num15;

        +.question-type-box {
          margin-top: $num10;
        }
      }
    }
  }

  .drag-drop-area {
    padding: $num10 !important;
  }

  .formwithcontent {
    .formcontent {
      padding-right: 30px;

      h1 {
        font-size: 30px;
      }
    }

    .requestregister-form {
      max-width: 450px;
    }
  }

  .survey-wrapper.intro_survey_wrap {
    width: $per100;
  }
}

@media (max-width: 991.98px) {

  header {
    .user-dropdown {
      margin-left: $num0;
    }
  }

  .dashboard-main {
    .dashboard-details {
      .survey-card {
        padding: $num10 $num10;
        box-shadow: $num0 $num2 $num4 rgba($black, 0.25);
      }
    }
  }

  .dashboard-main .all-surveys .dashboard-data-wrap .all-survey-card {
    flex-wrap: wrap;

    .survey-title {
      width: $per100;
    }

    .survey-data>li {
      padding: $num5 $num5;
      width: $num65;

      .data-label-text {
        margin-top: $num2;
        font-size: $num10;
      }
    }
  }

  .border-btns {
    border: $num1 solid $bordercolor;
    margin-top: $num15;
  }

  .secondary-header {
    .create-survey-flow {
      padding: $num12 $num0;

      .left-menu {
        ul {
          li {
            a {
              font-size: $num10;
            }
          }

          li+li {
            margin-left: $num10;
          }
        }
      }

      .add-question-right {
        .total-question-count {
          font-size: $num10;
          padding-right: $num10;
          margin-right: $num10;
        }

        .btn-grad {
          padding: $num8 $num12;
          font-size: $num12;
          min-height: $num36;
        }
      }


    }
  }

  .add-question-main {
    .add-question-form {
      .question-main {
        .question-main-top {
          margin-top: 25px;

          .question-edit {
            border-left: $num0;
            padding-left: $num0;
            margin: $num5 $num0 $num0;
            width: $per100;
            justify-content: flex-start;
            display: inline-block;
            line-height: normal;
          }

          flex-wrap: wrap;
        }
      }

      .question-type-box {
        .required-switch {
          .form-switch {
            flex-wrap: wrap;
    }
  }

        .survey-question-required {
          width: $per100 !important;
          border-left: $num0;
          padding: $num0;
          margin-top: $num5;

          .error-box {
            width: 100%;
          }
        }
      }
    }
  }

  .normal-mode {
    .rating_questions {
      .group_rating_que {
        flex-wrap: wrap;
        margin-top: $num10;

        span {
          margin-bottom: $num0;
        }

        span.k-rating {
          margin-bottom: $num0;
          width: $per100;
        }
      }
    }
  }

  .rating_questions {
    br {
      display: none;
    }
  }

  .edit-organization {
    .organization-form {
      .edit-org-form {
        flex-wrap: wrap;

        form {
          width: $per100;
          flex: auto;
        }
      }

      .organization-info {
        margin-left: $num0;
        margin-top: $num20;
      }
    }
  }

  .formwithcontent {
    display: block;

    .formcontent {
      padding-right: 0;

      h1 {
        font-size: 24px;
        margin-bottom: 8px;
      }
    }
  }


}

@media (min-width: 320px) and (max-width: 768px) {}

@media (max-width: 767.98px) {

  .filter-list-top {
    position: relative;

    .search-input-main {
      position: absolute;
      left: 0;
      right: 0;
      z-index: 999;
      background: #fff;

      .form-control {
        min-width: auto;
}
    }
  }


  footer.footermobile {
    max-height: unset;

    .row {
      >*+* {
        margin-top: $num5;
      }
    }
  }

  .registration_note {
    position: absolute;
  }

  .dashboard-main {
    .all-surveys {
      .dashboard-data-wrap.my_survey_list {
        .k-listview-content {
          .all-survey-card {
            .survey_card_footer {
              .survey-tag {
                span.survey-status {
                  span {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .thankyou-message {
    padding: $num0 !important;
  }

  .question-main {
    .form-check.group_rate {
      flex-direction: column-reverse !important;
      align-items: flex-start !important;

      label {
        max-width: $per100 !important;
      }
    }
  }

  .k-rating-container {
    .k-rating-item {
      .k-icon {
        font-size: $num20;
      }
    }
  }

  .survey-wrapper.survey_panel {
    .left-panel {
      display: none;
    }
  }

  .survey-wrapper {
    .right-panel {
      width: $per100 !important;
      left: $num0 !important;
    }

    .surveyDescription {
      h5 {
        font-size: $num14;
      }
    }

    .survey-timings {
      font-size: $num11;

      span.start_date,
      span.end_date {
        font-size: $num12;
      }

      span.end_date {
        padding-left: $num5;
      }
    }
  }

  .question-main {
    .form-check {
      .k-rating-container {
        .k-rating-item {
          padding-right: $num4;
        }
      }
    }

    .question-text {
      word-break: break-word;
    }
  }

  .survey-wrapper.intro_survey_wrap {
    width: $per100;
  }

  header.inner_header {
    .navbar {
      flex-wrap: nowrap;

      .navbar-toggler {
        border: $num0;
        padding: $num0;
      }

      ul.navbar-nav li {
        a {
          padding: $num10 $num12;
          display: block;
        }

        &+li {
          margin: $num0;
          border-top: $num1 solid rgba($black, 0.1);
        }
      }
    }

    .navbar-collapse {
      display: block !important;
      position: fixed;
      top: calc(#{$num60} + #{$num1});
      width: $num200;
      left: -$num250;
      background-color: $white;
      box-shadow: $num3 $num6 $num8 rgba($black, 0.25);
      height: $per100;
      -webkit-transition: 0.3s;
      transition: 0.3s;

      &.show {
        left: $num0;
      }
    }
  }

  .add-question-main,
  .profile-main,
  .survey-settings-main {
    .right-panel {

      .create-survey-form,
      .add-question-form {
        button {
          min-width: $num130;
        }
      }
    }
  }

  .secondary-header {
    max-height: $num70;

    .create-survey-flow {
      padding: $num5 $num0;
      flex-flow: row-reverse;
      position: relative;
      min-height: unset;
      height: calc(60px + 4px);

      .left-menu {
        ul {
          display: none;
          background-color: $white;
          box-shadow: $num0 $num10 $num20 $num0 rgba($black, 0.1);
          border: $num1 solid $bordercolor;
          position: absolute !important;
          top: $per100 !important;
          right: $num0 !important;
          min-width: $num150;
          left: auto !important;

          li {
            a {
              width: $per100;
              padding: $num8;
              color: $primaryColor;
              border-bottom: $num1 solid $bordercolor;
              text-align: left;
              border-radius: $num0;
              position: relative;
              transition: all 0.3s;
              letter-spacing: $num1;
              font-weight: $medium;
              display: block;
            }
          }

          li+li {
            margin-left: $num0;
          }
        }

        ul.show {
          display: block;
        }

        .menu-toggle-btn {
          &:after {
            display: none;
          }
        }
      }

      .right-menu {
        height: 100%;
        display: flex;
        align-items: flex-end;
      }

      .add-question-right {
        flex-wrap: wrap;
        text-align: left;
        justify-content: flex-start;

        .total-question-count {

          // margin-bottom: $num4;
          &::after {
            top: $num0;
            bottom: $num0;
            height: unset;
            margin-top: unset;
          }

          &.survey-status {
            margin-bottom: $num0;
            padding-right: $num0;

            &::after {
              opacity: $num0;
            }
          }
        }

        .statusUpdate-btn {
          width: $per100;
          position: absolute;
          left: $num0;
          bottom: $num5;
        }

      }
    }
  }

  .left-menu {
    position: relative;

    .menu-toggle-btn {
      padding: $num8 $num12;
      background-color: $white;
      font-size: $num12;
      letter-spacing: $num1;

      svg {
        font-size: $num18;
      }
    }
  }

  .survey-wrapper {
    .left-panel {
      top: $num125;
      left: -$num260;
      max-height: calc(#{$vh100} - #{$num120});
      z-index: 10000;

      .accordion {
        .accordion-item {
          h2 {
            .accordion-button {
              font-size: $num12 !important;
              padding: 1rem $num10 !important;
            }
          }
        }
      }
    }

    .left-panel.slideLeft {
      width: $num260;
      left: $num0;
    }
  }

  .mobile-left-toggle-main {
    position: fixed;
    top: $num125;
    left: $num0;
    width: $per100;
    background-color: $white;
    padding: $num5 $num12;
    z-index: 998;

    .toggle-left.mobile-left-toggle {
      cursor: pointer;
      text-align: center;
      text-transform: capitalize;
      font-weight: $medium;
      transition: 0.5s;
      background-size: $per200 auto;
      color: $white;
      border-radius: $num0;
      border: $num1 solid $bodyBGColor;
      box-shadow: $num0 0.125rem 0.25rem rgba($black, 0.08) !important;
      padding: $num5 $num10;
      font-size: $num12;
      background-image: linear-gradient(to right, $blue $per0, $lightblue $per51, $blue $per100);
      letter-spacing: $num1;
      display: inline-block;

      svg {
        font-size: $num22;
      }
    }

    + {
      .right-panel {
        margin-top: $num45;
      }
    }
  }

  .survey-wrapper {
    .left-panel {
      .accordion {
        .accordion-item {
          .accordion-collapse {
            .accordion-body {
              .btn {
                font-size: $num12 !important;
                display: flex !important;
                place-content: flex-start !important;

                svg {
                  margin-right: $num10 !important;
                  padding-right: $num10 !important;
                  border-right: $num1 solid $bordercolor !important;
                  padding-left: $num0 !important;
                }
              }
            }
          }
        }
      }
    }
  }

  .wrapper {
    padding: $num125 $num0 $num30;
  }

  header.inner_header+.wrapper {
    .mobile-left-toggle-main {
      top: $num60;
    }
  }

  .survey-wrapper {
    .left-panel {
      nav {
        .nav-link {
          font-size: $num12 !important;
          display: block !important;
          place-content: flex-start !important;

          svg {
            margin-right: $num10 !important;
            padding-right: $num10 !important;
            border-right: $num1 solid $bordercolor !important;
            padding-left: $num0 !important;
          }
        }
      }
    }
  }

  .feeback_chat_popup {
    .chat_popup {
      bottom: $num100;
      height: calc(#{$per100} - #{$num165});
    }
  }

  #check:checked~.chat_popup {
    margin-left: -$num330;
  }

  .dashboard-main {
    .all-surveys {
      .dashboard-data-wrap {
        padding: $num20 $num12;
      }
    }
  }

  .heading-actions {
    display: block;

    .action {
      display: block;
      margin-top: $num10;

      .filter,
      .sort {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .dashboard-main {
    .all-surveys {
      .dashboard-data-wrap.my_survey_list {
        .k-listview-content {
          .survey-card-box {
            padding: $num15 $num10 $num36 $num10;

            .survey_card_footer {
              padding: $num5 $num10;
            }
          }
        }
      }
    }
  }

  .filter-main {
    .filter-inner {
      .filter-box {
        .filter-date-box {
          column-gap: $num0;
        }
      }
    }
  }

  div:has(.mobile-left-toggle-main) {
    .secondary-header {
      box-shadow: none !important;
    }
  }

  .datarequest-box {
    h1 {
      font-size: 26px;
    }

    .datarequest-box-inner {
      padding: 15px;
    }
  }

  .secondary-header .create-survey-flow .add-question-right:has(.d-md-none.w-100:nth-child(2)) .total-question-count:nth-child(1) {
    padding-right: 0;
    margin-right: 0;

    &:after {
      opacity: 0;
    }
  }

  .question-main .view-media-btn {
    right: 16px;
  }
}

@media (max-width: 650px) {
  .custom-pagignation {
    flex-wrap: wrap;

    ul.pagination {
      overflow: auto;
      margin-bottom: 5px;
    }
  }

  .login-reg-main {
    .form-account {
      form {
        .form-input-main {
          margin-bottom: $num10;
        }

        .otp-counter {
          float: left;
        }
      }
    }
  }

  header.intro_header {
    padding: $num10 $num0;

    h4 {
      font-size: $num16;
    }
  }

  .introduction-main {
    p.survey-date {
      font-size: $num11;

      span.start_date,
      span.end_date {
        font-size: $num12;
      }

      span.end_date {
        padding-left: $num2;
      }
    }

    h5 {
      font-size: $num16;
    }

    .px-5 {
      padding: $num0 !important;
    }
  }

  .thankyou-main {
    .p-5 {
      padding: $num0 !important;
    }

    .thankyou-message-card {
      .thankyou-message {
        font-size: $num14;

        h4 {
          font-size: $num20;
        }
      }
    }
  }

  .survey-wrapper {
    .left-panel.slideLeft {
      .btn.btn-added {
        width: $per90;
        font-size: $num10;
        padding: $num5;
      }
    }
  }


  .survey-dates {
    &>span {
      &:nth-child(4) {
        display: block;
      }
    }
  }

}

@media (max-width: 575.98px) {

  .dashboard-main {
    .all-surveys {
      .dashboard-data-wrap {
        .all-survey-card {
          padding-right: 15px;

          .survey-data {
            justify-content: flex-start;
            flex-wrap: wrap;
          }
        }
      }
    }
  }

  .alert {
    top: $num125;
  }

  .login-reg-main {
    .form-account {
      form {
        .previous-next {
          display: block !important;

          .btn-grad {
            width: $per100;
          }
        }
      }
    }
  }

  .survey-dates {
    &>span {
      display: block;
    }
  }

  .login-reg-main {
    .registration-box-height {
      .form-account {
        width: $per100;
        right: $num15;
        left: $num0;
      }
    }

    .form-account {
      form {
        .previous-next {
          button.btn-next {
            right: -$num30 !important;
          }

          button.btn-prev {
            left: -$num30 !important;
          }
        }
      }
    }

    .reg-timeline {
      span:before {
        font-size: $num9;
      }
    }
  }

  .normal-mode {
    .data-options {
      span {
        font-size: $num10;
      }
    }
  }

  .non-edit-data {
    p {
      flex-wrap: wrap;
      padding: $num5 $num10;

      span.edit-icon {
        margin-left: $num80;
        width: $per100;
      }

      span {
        min-width: $num80;
      }
    }

    p.email-verified {
      padding-left: $num90;
    }
  }

  .organization-primary {
    .non-edit-data {
      p {
        span {
          padding-right: $num0;
          width: $per100;
        }
      }
    }
  }


  .feeback_chat_popup {
    .chat_popup {
      width: $per100;
    }
  }

  #check:checked~.chat_popup {
    margin-left: $num0;
    left: $num0;
  }

  // .add-question-main {
  //   .add-question-form {
  //     .question-type-box {
  //       .required-switch {
  //         .form-switch {
  //           flex-wrap: wrap;
  //         }
  //       }

  //       .survey-question-required {
  //         width: $per100 !important;
  //         border-left: $num0;
  //         padding: $num0;
  //         margin-top: $num5;
  //       }
  //     }

  //     .question-main {
  //       .question-main-top {
  //         margin-top: 25px;
  //       }
  //     }
  //   }
  // }

  .personal-details-form {
    padding: 0 12px 20px !important;

    .datarequest-box-header {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      justify-content: flex-start;
      padding: 20px 12px;
      min-height: unset;
      row-gap: 6px;
      margin: 0 -12px 12px;

      h1 {
        width: 100%;
        font-size: 22px;
      }

      .link-new-request {
        width: 100%;
      }
    }

    .status-date {
      font-size: 13px;
      margin-top: 16px;
    }

    .request-info-btn {
      margin-top: 16px;

      p {
        display: inline-block;
        vertical-align: top;
      }
    }

    .history-timeline {
      padding: 16px 12px;
      border-radius: 0;
      margin: 0 -12px;
      font-size: 12px;

      &:last-child {
        margin-bottom: -20px;
      }

      .ht-head {
        margin-bottom: 2px;

        h5 {
          font-size: 16px;
        }
      }

      .ht-inner {
        margin-top: 12px;

        .ht-status .ht-status-inner {
          padding: 0 4px;
          font-size: 10px;

          h6 {
            font-size: 12px;
            margin-bottom: 2px;
          }
        }
      }
    }
  }

  .secondary-header .create-survey-flow {
    display: block;
    width: 100%;

    .left-menu {
      position: absolute;
      right: 0;
      bottom: 5px;

      .menu-toggle-btn {
        padding: 6px 10px;
      }
    }

    .add-question-right {
      .total-question-count {
        padding-right: 6px;
        margin-right: 6px;
        margin-bottom: 6px;

        &+.total-question-count {
          padding-right: 0;
          margin-right: 0;

          &:after {
            opacity: 0;
          }
        }
      }

      .btn-grad {
        padding: 5px 10px;
        min-height: 32px;
      }
    }
  }

  .survey-timings span.end_date:before,
  .introduction-main .intro-survey-date>span.end_date {
    content: '';
    display: block;
    margin-top: 2px;
  }

  .org-info-popup .survey-timings span.end_date:before {
    margin-top: 0;
  }

  .introduction-main .intro-survey-date>span.end_date {
    margin-left: 0;
  }
}

@media (max-width: 499.98px) {
  .heading-actions {
    .action {

      .filter,
      .sort {
        display: block;
      }

      .dropdown {
        flex-wrap: wrap;
        justify-content: flex-start;
      }

      div+div {
        margin-left: $num0;
        margin-top: $num8;
      }

      .action-area~.action-area {
        margin-left: $num5;
      }
    }
  }

}
@media (max-width: 399.98px) {
  .secondary-header {
    .create-survey-flow {
      .add-question-right {
        .publish-dropdown-main {
          .publish-dropdown {
            right: auto;
            left: -$per50;
            transform: translateX(-$num42);
          }
        }
      }
    }
  }
}
