/*--- Links --- */
@import "fonts";
@import "colors";
@import "numbers";
@import "breakpoints";
/*--- End Links --- */

/* mixins */

@mixin flex($flex, $align-items, $justify-content) {
  display: $flex;
  align-items: $align-items;
  justify-content: $justify-content;
}

@mixin action-box-bg {
  background: $white;
  background: -moz-linear-gradient(top, $white $per0, $bodyBGColor $per100);
  background: -webkit-linear-gradient(top, $white $per0, $bodyBGColor $per100);
  background: linear-gradient(to bottom, $white $per0, $bodyBGColor $per100);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='$white', endColorstr='$bodyBGColor', GradientType=0);
}

/* scroll bar */

/* width */
::-webkit-scrollbar {
  width: $num5;
  height: $num5;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset $num0 $num0 $num5 $bordercolor;
  border-radius: $num0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $lightGrey;
  border-radius: $num10;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $grey;
}

// common
html,
body {
  overflow-x: hidden !important;
}

body {
  margin: $num0;
  padding: $num0;
  font-family: $primaryFontFamily;
  font-size: $num14;
  color: $primaryColor;
  background-color: $bodyBGColor;
  position: relative;
}

b,
strong {
  font-weight: $bold;
}

.k-overlay {
  opacity: 0.2;
}

.k-input,
.k-picker {
  font-family: inherit;
}

.k-input-md,
.k-picker-md {
  font-size: $num13;
}

.surveypro-loader {
  @include flex(flex, center, center);
  padding: $num50 $num15;
  height: $per100;

  .spinner-border {
    width: $num100;
    height: $num100;
  }

  .h4 {
    margin-top: $num40;
    margin-bottom: $num0;
  }
}

.fullpage-loader .surveypro-loader {
  height: calc(#{$vh100} - #{$num49});
}

.manage-list-loader {
  min-height: calc(#{$vh100} - #{$num405});
}

div:has(> .datacenter-loader) {
  @include flex(flex, flex-start, unset);
  flex-wrap: wrap;

  &>div {
    width: $per100;
  }
}

.k-window {
  font-size: inherit;
}

.text-red {
  color: $red;
}

.text-green {
  color: $green;
}

:focus-visible {
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

*::selection {
  background-color: $primaryColor !important;
  color: $white !important;
}

.k-checkbox {
  border-color: $bordercolor !important;

  &.k-invalid+.k-checkbox-label {
    color: inherit;
  }
}

.container-fluid {
  padding: $num0 $num50;
}

.minHeight {
  min-height: calc(#{$vh100} - #{$num50});
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: $num0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
  appearance: textfield;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
  transition: all 0.3s;
}

p {
  margin: $num0;
}

button {
  transition: all 0.3s;
}

img {
  max-width: $per100;
}

.form-control:focus {
  border-color: $secondaryColor;
  box-shadow: none;
}

button:focus,
.form-check-input:focus {
  box-shadow: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $semibold;
}

.all-disable {
  pointer-events: none;
  opacity: 0.9;
}

.ReactModalPortal {
  position: relative;
  z-index: 10000;
}

.password_hint {
  margin: -#{$num2} $num0 $num0 $num5;

  svg {
    pointer-events: none;
  }
}

.survey_note {
  background: $white;
  box-shadow: rgba($black, 0.15) $num0 $num8 $num24 !important;
  padding: $num15;
  border-radius: $num10;

  a {
    color: $secondaryColor;
    text-decoration: underline;
  }

  strong {
    font-size: $num13;
    font-weight: $bold;
  }
}

.registration_note {
  padding: $num10 $num50 $num10 $num40;
  background: $bordercolor;
  border-left: $num10 solid $secondaryColor;
  left: $num0;
  position: fixed;
  right: $num0;
  top: $num58;
  z-index: 999;

  h6 {
    margin-bottom: $num0;
  }
}

.btn {
  border: none;
  color: $white;
  border-radius: $num0;
  font-size: $num14;
  padding: $num8 $num0;

  &.disabled {
    opacity: 0.2;
    color: $white;
  }

  &:focus+.btn,
  &:focus {
    outline: $num0;
    box-shadow: none;
  }

  &:hover {
    background-color: $primaryColor;
    color: $white;
  }
}

.ptb_30 {
  padding: $num30 $num0;
}

.ptb_75 {
  padding: $num75 $num0;
}

.btn-prev {
  color: $white;
  font-size: $num12;
  padding: $num10;
  background-color: $primaryColor;
  border: none;
}

.btn-next {
  color: $white;
  font-size: $num12;
  padding: $num10;
  background-color: $secondaryColor;
  border: none;
}

.btn-grad-blue {
  background-image: linear-gradient(to right, $blue $per0, $lightblue $per51, $blue $per100);
}

.btn-grad-black {
  background-image: linear-gradient(to right, $primaryColor $per0, $lightblack $per51, $primaryColor $per100);
}

.btn-grad-white {
  background-image: linear-gradient(to right, #ece9e6 $per0, $white $per51, #ece9e6 $per100);
}

input[type="checkbox"]:checked {
  background-color: $secondaryColor !important;
  color: white;
}

.k-radio:checked,
.k-radio.k-checked {
  background-color: $secondaryColor;
  border-color: $secondaryColor;
}

.k-radio:checked:focus,
.k-radio.k-checked.k-focus {
  border-color: $secondaryColor;
  box-shadow: $num0 $num0 $num0 0.25rem rgba($secondaryColor, 0.25);
}

.btn-grad {
  padding: $num10 $num25;
  text-align: center;
  font-weight: $medium;
  transition: 0.5s;
  background-size: $per200 auto;
  color: $white;
  border-radius: $num0;
  display: inline-block;
  border: $num1 solid $bodyBGColor;
  font-size: $num14;
  box-shadow: $num0 0.125rem 0.25rem rgba($black, 0.075) !important;

  &:hover {
    background-position: right center;
    /* change the direction of the change here */
    box-shadow: $num0 $remh $rem1 rgba($black, 0.15) !important;
    color: $white;
  }
}

.btn {
  &.btn-text-only {
    box-shadow: none !important;
    padding: $num0 !important;
    min-width: unset !important;
    margin: $num0 $num25 !important;
    background: transparent !important;
    border: $num0 !important;
  }

  &.text-red {
    color: $red;

    &:hover {
      color: inherit;
    }
  }
}

.inline-button {
  padding: $num10 $num25;
  text-align: center;
  text-transform: capitalize;
  font-weight: $medium;
  transition: 0.5s;
  color: $white;
  margin: $num0 !important;
  background: transparent;
  border: none;
  border-right: $num2 solid $bodyBGColor;
  box-shadow: none;

  &:hover {
    box-shadow: $num0 0.125rem $remh rgba($black, 0.075);
    color: $primaryColor;
    background-color: $white;
    box-shadow: $num0 $remh $rem1 rgba($black, 0.15) !important;
  }

  &:last-child {
    border-right: none;
  }
}

.color-secondary {
  color: $secondaryColor;
}

.leftButtons {
  width: $per100;
  padding: $num8;
  color: $primaryColor;
  border-bottom: $num1 solid $bordercolor;
  text-align: left;
  border-radius: $num0;
  position: relative;
  font-size: $num12;
  transition: all 0.3s;
  letter-spacing: $num1;
  font-weight: $medium;
  @include flex(flex, center, flex-start);
}

.swal2-container {
  z-index: 100006 !important;
}

.k-textarea {
  height: $num80;
}

.k-input-solid:focus,
.k-input-solid.k-focus,
.k-input-solid:focus-within {
  box-shadow: $num0 $num20 $num30 $num0 rgba($black, 0.08);
  border-color: $secondaryColor;
}

.k-rounded-md {
  border-radius: $num0;
}

.k-dropdown,
.k-dropdowntree {
  font-family: inherit;
}

.k-textbox-container {
  .k-label {
    font-weight: $medium;
    font-size: $num12;
    padding-top: $num6;
    color: #212529;
  }
}

.k-tooltip {
  max-width: $num200;
  font-size: $num12;
}

.k-textarea.k-state-invalid:focus-within {
  box-shadow: none;
}

.k-switch {
  width: $num50;

  .k-switch-container {
    border-color: $secondaryColor;
    background-color: $secondaryColor;
    width: $num35;
  }

  .k-switch-handle {
    width: $num16;
    height: $num16;
  }

  &.k-switch-md .k-switch-track {
    width: $num50;
    outline: unset;
  }

  &.k-switch-on .k-switch-track {
    border-color: $secondaryColor;
    background-color: $secondaryColor;
    color: $white;
  }
}

.k-switch.k-switch-on {
  .k-switch-handle {
    left: calc(#{$per100} - #{$num18});
    top: -#{$num2};
  }
}

.k-switch.k-switch-off {
  .k-switch-thumb {
    background-color: #d8d8d8;
  }

  .k-switch-track {
    border-color: #d8d8d8;
  }

  .k-switch-container {
    background-color: transparent;
  }

  .k-switch-handle {
    background-color: $secondaryColor;
    left: $num3;
    top: -#{$num1};
  }
}

.k-switch-off:focus .k-switch-track,
.k-switch-off.k-focus .k-switch-track {
  box-shadow: none;
  outline: unset;

  .k-switch-track {
    outline: unset;
    border-color: $bordercolor;
  }
}

.k-animation-container-shown {
  // z-index: 99999 !important;
  z-index: 99 !important;
}

.k-daterangepicker {
  .k-daterangepicker-wrap {
    display: block;
    width: $per100;

    .k-textbox-container {
      width: calc(#{$per50} - #{$num10});

      +span.k-textbox-container {
        margin-left: $num15;
      }

      .k-dateinput {
        .k-dateinput-wrap {
          border: none !important;
        }
      }
    }
  }
}

.k-editor-content {
  .k-input {
    margin: $num0;
    box-shadow: none;
    border: none;
  }

  iframe.k-iframe {
    height: auto !important;
  }
}

.k-button-group .k-button:active,
.k-button-group .k-button.k-state-active,
.k-button-group .k-button.k-state-selected,
.k-button-group>input[type="radio"]:checked+.k-button,
.k-button-group>input[type="checkbox"]:checked+.k-button,
.k-button.k-primary {
  border-color: $secondaryColor;
  background-color: $secondaryColor;
  background-image: linear-gradient(to right, #32ffff -50%, $secondaryColor 50%, #32ffff 150%);
}

.k-grid {
  min-width: $num600;

  .k-input {
    border-radius: $num0;
    border: $num1 solid $bordercolor !important;
    box-shadow: $num0 $num2 $num2 $num0 rgba($black, 0.03);
    font-size: $num13;
    padding: $num0 $num10;
    font-weight: $regular;
    height: $num36;

    &:focus {
      box-shadow: $num0 $num20 $num30 $num0 rgba($black, 0.08) !important;
      border-color: $secondaryColor !important;
    }
  }

  .k-rounded-md {
    &.k-icon-button {
      border-radius: 4px;

      svg {
        pointer-events: none;
      }
    }

    &+.k-icon-button {
      margin-left: 4px;
    }
  }
}

.k-grid .k-grid-header {
  padding-inline-end: $num0;

  .k-table-th {
    font-weight: $semibold;
  }
}

.k-grid-content {
  overflow: hidden;
}

.k-dialog {
  max-width: $per90;
  width: $num700;
  max-height: $per96;

  .k-dialog-titlebar {
    background-color: $secondaryColor;
    white-space: break-spaces;
    color: $white;
    text-transform: capitalize;

    .k-dialog-titlebar-action {
      opacity: 1;
    }
  }

  .dialog-note {
    margin: -#{$num10} $num0 $num10;
    position: relative;
    z-index: 5;
    color: #999;
  }

  .k-picker-md .k-input-inner {
    border-right: $num1 solid #cbcbcb;
  }

  .k-picker-solid:focus .k-input-inner,
  .k-picker-solid.k-focus .k-input-inner {
    border-right: $num1 solid #aeaeae;
  }
}

.k-grid .k-grid-content tr:nth-child(1):last-child td {
  border-bottom: $num1 solid #dee2e6;
}

.k-datepicker,
.k-timepicker,
.k-datetimepicker {
  width: $per100;

  .k-picker-wrap {
    border: none;
    border-radius: $num0;
    height: $per100;

    &.k-state-focused {
      box-shadow: $num0 $num20 $num30 $num0 rgba($black, 0.08);
      border: $num1 solid $secondaryColor;
    }
  }

  .k-select {
    border-radius: $num0;
  }
}

.k-pager-wrap.k-pager.k-widget {
  background: transparent;
  border: none;
  padding: $num10 $num10 $num0;

  .k-pager-numbers {
    .k-link {
      color: $primaryColor;

      &.k-state-selected,
      &:hover {
        color: $white;
        background-color: $secondaryColor;
      }
    }
  }
}

.survey-wrapper.intro_survey_wrap {
  width: $per65;
  margin: $num0 auto;

  .submitted_survey {
    min-height: calc(#{$vh100} - #{$num174});
    @include flex(flex, center, center);

    h4 {
      color: $secondaryColor;
    }

    p {
      color: $secondaryColor;
      font-size: $num18;
      letter-spacing: $num1;
      font-weight: $semibold;
    }

    .submitted_survey_icon {
      margin: $num0 $num0 $num15;
      color: $secondaryColor;
      font-size: $num80;
      position: relative;
      line-height: $num0;
    }
  }

  .surveyDescription {
    h5 {
      font-weight: $bold;
      text-transform: uppercase;
      font-size: $num20;
      margin: $num15 $num0 $num5;
    }
  }
}

/* forms */
form {
  .form-input-main {
    margin: $num0 $num0 $num15;
    position: relative;

    &:focus-within {
      z-index: 2;
    }

    input {
      border-radius: $num0;
    }

    input.k-radio {
      border-radius: $per50;
    }

    label {
      position: relative;
      padding: $num0;
      text-align: left;
      display: block;
      margin: $num0 $num0 $num5;
      font-size: $num13;
      font-weight: $semibold;
    }

    label.k-radio-label {
      font-weight: $regular;
    }

    .form-check-inline {
      vertical-align: top;
      min-height: unset;
      margin-bottom: $num0;

      .form-check-input {
        width: $num14;
        height: $num14;
        font-size: $num16;
        margin-left: -#{$num21} !important;
      }

      .form-check-label {
        font-weight: $medium;
        margin-bottom: $num0;
        margin-top: $num3;
      }
    }

    .k-dropdown,
    .k-combobox {
      width: $per100;
      height: $num45;
      box-shadow: $num0 $num2 $num2 $num0 rgba($black, 0.03);
      border: none;

      .k-dropdown-wrap {
        background-color: $white;
        background-image: none;
        border: $num1 solid $bordercolor;
        border-radius: $num0;
        font-size: $num12;

        &.k-state-focused {
          box-shadow: $num0 $num20 $num30 $num0 rgba($black, 0.08);
          border-color: $secondaryColor;
        }

        .k-input {
          height: $per100;
          font-size: $num13;
          font-weight: $medium;

          &::placeholder {
            font-size: $num12;
            letter-spacing: $num0 !important;
            font-weight: $medium;
            color: $primaryColor;
            font-family: $primaryFontFamily;
          }
        }

        .k-select {
          border-color: transparent;
          background-color: transparent;
        }
      }
    }

    .dropdown_label {
      .k-textbox-container {
        width: $per100;
      }
    }

    .show-hide-password {
      position: absolute;
      right: $num15;
      top: $num36;
      font-size: $num14;
      cursor: pointer;
    }

    input[type="password"] {
      padding-right: $num30;
    }
  }

  .form-label {
    color: $primaryColor;
    font-weight: $semibold;
    font-size: $num14;
    margin-bottom: $num5;

    span {
      color: $red;
    }
  }

  .form-control,
  .form-select {
    border-radius: $num0;
    border: $num1 solid $bordercolor !important;
    box-shadow: $num0 $num2 $num2 $num0 rgba($black, 0.03);
    font-size: $num13;
    padding: $num0 $num15;
    font-weight: $medium;
    height: $num45;

    &::placeholder {
      font-size: $num12;
      letter-spacing: $num0 !important;
      font-weight: $medium;
      color: $primaryColor;
    }

    &:focus {
      box-shadow: $num0 $num20 $num30 $num0 rgba($black, 0.08) !important;
      border-color: $secondaryColor !important;
    }

    &:disabled,
    &.k-disabled {
      background-color: $bodyBGColor;
    }

    &.k-combobox {
      padding: $num0;
    }
  }

  textarea.form-control {
    min-height: $num90;
    padding: $num15;
  }

  .back-to-last {
    display: table;
    margin: $num0 $num0 $num0 auto;
    background-color: $primaryColor;
    font-size: $num12;

    &:hover {
      background-color: $secondaryColor;
    }
  }

  p.text-danger {
    margin-top: $num5;
    font-size: $num12;
    color: $red !important;

    &.privacy-error {
      bottom: -#{$num20};
      font-weight: $regular;
    }
  }
}

.wrapper {
  padding: $num115 $num0 $num50;
}

/*
  form questions
*/
.question-main {
  margin: $num10 $num0 $num10;

  .question-number {
    font-size: $num12;
    margin: $num0 $num0 $num10;
    line-height: 1;
  }

  .view-media-btn {
    position: absolute;
    top: 8px;
    right: 24px;
    padding: 5px 10px 3px;
    font-size: 10px;
    letter-spacing: 1px;

    &.report_flag {
      position: relative;
      top: auto;
      right: auto;
      left: 25px;
    }
  }

  &:has(.view-media-btn) .question-text.d-flex {
    margin-top: 16px;
  }

  .question-text {
    font-size: $num13;
    font-weight: $bold;
    margin: $num0 $num0 $num4;
    position: relative;
    word-break: break-word;

    &:last-child {
      margin-bottom: $num0;
    }

    &.report_flag {
      display: flex;
      align-items: center;
      justify-content: space-between;

      button.report_flag {
        background: transparent;
        border: 0;
      }
    }
  }

  .form-check {
    margin: $num0 $num0 $num10;
    font-weight: $medium;
    font-size: $num13;
    position: relative;

    &:last-child {
      margin: $num0;
    }

    .form-check-input {
      &:checked {
        z-index: 9;

        ~label {
          ~input[type="text"] {
            display: block;
          }
        }

        &[value="Other"] {
          ~label {
            display: inline-block;
            width: $num100;

            ~input[type="text"] {
              display: inline-block;
              width: calc(#{$per100} - #{$num110});
              margin-left: $num10;
            }
          }
        }
      }
    }

    .form-check-label {
      word-break: break-word;
    }

    input[type="text"] {
      display: none;
    }

    .k-rating-container {
      margin: $num0;

      .k-rating-item {
        padding-left: $num0;
        padding-top: $num0;
        padding-bottom: $num0;
      }
    }

    small {
      display: block;
      font-size: $num11;
      color: #bbbbbb;
    }
  }

  .form-check.group_rate {
    margin: $num0;
    border-bottom: $num1 dashed #e5e5e5;
    padding: $num10 $num0 $num12;
  }
}

button.request_demo {
  padding: $num8 $num10;
  font-size: $num11;
  border-radius: $num10;
  border-top-right-radius: $num0;
  border-bottom-left-radius: $num0;
  font-weight: $semibold;
  font-family: "Outfit", sans-serif;
  background: $white;
  border: $num0;
  letter-spacing: $num1;
  color: $black;
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  border: $num2 solid #f4f5fd;

  &:hover {
    background: transparent;
    color: $black;
    transition: all 0.3s ease-in-out;
    border: $num2 solid;
  }
}

/* header */
header {
  padding: $num10 $num0 $num0 $num0;
  position: absolute;
  width: $per100;
  display: block;
  background: transparent;
  z-index: 999;
  height: auto;
  left: $num0;
  transition: all 0.3s linear 0s;

  a.user_login {
    font-weight: $bold;
    font-size: $num20;
    letter-spacing: $num1;
    line-height: $num32;
    color: $white;
    transition: all 0.3s ease-in-out;

    &:hover {
      color: $black;
    }
  }

  button.request_demo {
    padding: $num8 $num10;
    font-size: $num11;
    border-radius: $num10;
    border-top-right-radius: $num0;
    border-bottom-left-radius: $num0;
    font-weight: $semibold;
    font-family: "Outfit", sans-serif;
    background: $white;
    border: $num0;
    letter-spacing: $num1;
    color: $black;
    transition: all 0.3s ease-in-out;
    text-transform: uppercase;
    border: $num2 solid $white;

    &:hover {
      background: transparent;
      color: $black;
      transition: all 0.3s ease-in-out;
      border: $num2 solid;
    }
  }

  img.nav-logo {
    width: $num65;
  }

  span.brand_name {
    font-family: "Outfit", sans-serif;
    color: $white;
    font-size: $num32;
    font-weight: $bold;
    letter-spacing: $num1;
    padding-left: $num15;
  }

  .navbar {
    padding: $num0;

    ul.navbar-nav {
      li {
        +li {
          margin-left: $num20;
        }

        a {
          font-weight: $bold;
          font-size: $num16;
          cursor: pointer;
          letter-spacing: $num1;
          line-height: $num20;
          font-family: "Outfit", sans-serif;
          color: $white;

          &:hover {
            color: $black;
          }

          svg {
            vertical-align: -#{$num3};
            font-size: $num16;
          }
        }
      }

      li.active {
        a {
          color: $secondaryColor;
        }
      }
    }
  }

  /* -.- dropdown -.-*/
  .user-dropdown {
    position: relative;
    background-color: $secondaryColor;
    color: $white;
    margin-left: $num15;

    .dropdown-toggle {
      @include flex(flex, center, center);
      font-size: $num12;
      text-transform: uppercase;
      letter-spacing: $num1;
      font-weight: $semibold;
      padding: $num15;

      &::after {
        border-top-width: $emh;
        border-right-width: $emh;
        border-left-width: $emh;
      }

      %avatar {
        background: $white;
        color: $secondaryColor;
        display: grid;
        place-content: center;
        font-size: $num12;
        font-weight: $semibold;
        margin: $num0 $num5 $num0 $num0;
      }

      @mixin avatar($size, $circle: false) {
        width: $size;
        height: $size;

        @if $circle {
          border-radius: $size / 2;
        }
      }

      .square-av {
        @include avatar($num30, $circle: false);
        @extend %avatar;
      }

      .circle-av {
        @include avatar($num30, $circle: true);
        @extend %avatar;
      }
    }

    .dropdown-menu {
      border: none;

      &[data-bs-popper] {
        right: $num0;
        left: auto;
      }

      li {
        +li {
          margin-left: auto !important;
        }

        .dropdown-item {
          text-transform: capitalize;

          &.active {
            background-color: $secondaryColor;
            color: $white;
          }
        }
      }
    }
  }
}

header.fixed-me {
  box-shadow: $num0 $num6 $num8 -#{$num1} rgba($black, 0.13);
  z-index: 9999;
  position: fixed;
  top: $num0;
  left: $num0;
  background: $white;
  padding: $num15 $num0;
  transition: all 0.3s linear 0s;

  button.request_demo {
    background: $black;
    color: $white;
    border-color: $black;
  }

  a.user_login {
    color: $black;
  }

  img.black_logo {
    width: $num50;
  }

  span.brand_name {
    color: $black;
  }

  .navbar {
    ul.navbar-nav {
      li {
        a {
          color: $black;
        }
      }
    }

    .navbar-toggler-icon {
      background: $black !important;

      &:before {
        background: $black !important;
      }

      &:after {
        background: $black !important;
      }
    }
  }
}

header.intro_header {
  padding: $num10 $num0;
  display: block;
  width: $per100;
  z-index: 99;
  background-color: $white;
  position: sticky;
  top: $num0;
  box-shadow: $num0 $num5 $num20 $num0 rgba($black, 0.06);
  max-height: $per100;

  img.nav-logo {
    width: $num70;
  }

  h4 {
    margin-bottom: $num0;
  }
}

header.inner_header {
  left: $num0;
  position: fixed;
  right: $num0;
  top: $num0;
  z-index: 999999;
  padding: $num0;

  .navbar {
    padding: $num0;

    .container-fluid {
      padding-top: $num5;
      padding-bottom: $num5;
    }

    img.nav-logo {
      width: $num50;
    }

    ul.navbar-nav li a {
      font-size: $num12;
      font-weight: $medium;
      letter-spacing: $num1;
      text-transform: uppercase;
      color: $primaryColor;

      &.active {
        color: $secondaryColor;
      }

      svg {
        font-size: $num16;
        vertical-align: -#{$num3};
      }
    }
  }
}

/*  login  */

.login-reg-main {
  width: $per100;
  min-height: calc(#{$vh100} - #{$num49});
  @include flex(flex, center, center);
  padding: $num100 $num0 $num40;

  .registration-box-height {
    position: relative;
    z-index: 1;
  }

  .reg-timeline {
    @include flex(flex, center, center);
    padding-bottom: $num15;
    margin: auto;
    max-width: $num370;
    position: relative;
    width: auto;

    span {
      flex: 1;
      position: relative;

      svg {
        position: relative;
        font-size: $num20;
        color: $white;
        z-index: 1;
        width: $num30;
        height: $num30;
        background-color: $bordercolor;
        border-radius: $num100;
        display: block;
        margin: $num0 auto;
        padding: $num5;
      }

      &:before {
        content: "";
        z-index: 1;
        background-color: $bodyBGColor;
        font-size: $num12;
        margin: $num0 $num0 $num5;
        color: $primaryColor;
        display: block;
      }

      &::after {
        content: "";
        background-color: $bordercolor;
        height: $num1;
        position: absolute;
        left: $num0;
        right: -#{$per100};
        bottom: $num15;
      }

      &:first-child {
        &::before {
          content: "Your Details";
          text-align: left;
        }

        svg {
          margin-left: $num0;
        }
      }

      &:nth-child(2) {
        &::before {
          content: "Organization Details";
          text-align: center;
        }

        &::after {
          left: $per50;
          right: -#{$per100};
        }
      }

      &:nth-child(3) {
        &::before {
          content: "Verification";
          text-align: right;
        }

        &::after {
          content: none;
        }

        svg {
          margin-right: $num0;
        }
      }

      &:last-child {
        &::before {
          content: "Verification";
          text-align: right;
        }

        &::after {
          left: $per100;
          right: $num0;
        }

        svg {
          margin-right: $num0;
        }
      }

      &.bg-completed {
        &::before {
          color: $secondaryColor;
        }

        &::after {
          background-color: $secondaryColor;
        }

        svg {
          background-color: $secondaryColor;
        }
      }
    }
  }

  .form-account {
    padding: $num15;
    margin: auto;
    max-width: 550px;
    position: relative;
    // width: auto;
    width: 100%;

    h4 {
      position: relative;
      margin: $num0 $num0 $num12;
      padding: $num0 $num0 $num12;

      &::after {
        content: "";
        position: absolute;
        left: $num0;
        right: $num0;
        bottom: $num0;
        height: $num1;
        background: $white;
        background: -moz-linear-gradient(90deg, rgba($white, $num0) $per0, rgba($bodyBGColor, 1) $per50, rgba($white, $num0) $per100);
        background: -webkit-linear-gradient(90deg, rgba($white, $num0) $per0, rgba($bodyBGColor, 1) $per50, rgba($white, $num0) $per100);
        background: linear-gradient(90deg, rgba($white, $num0) $per0, rgba($bodyBGColor, 1) $per50, rgba($white, $num0) $per100);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$white", endColorstr="$white", GradientType=1);
      }
    }

    .surveypro-loader {
      width: #{$num500 + $num50};
      max-width: $per100;
    }

    form {
      img {
        width: $num80;
      }

      .checkbox {
        font-weight: $regular;

        input {
          vertical-align: middle;
        }

        small {
          font-size: $num10;
          font-weight: $regular;
        }
      }

      .form-input-main {

        /* register as radio */
        .form-check {
          min-height: auto;
          padding-left: $num0;
          margin: $num0 $num10 $num0 $num0;
          position: relative;

          input[type="radio"] {
            border-radius: $per50;
            position: absolute;
            top: $num0;
            left: $num0;
            border-radius: $num0 !important;
            margin: $num0;
            border: none;
            width: $per100;
            height: $per100;
            background-color: transparent;

            &:checked {
              width: $num12 !important;
              height: $num12 !important;
              left: auto !important;
              z-index: 9;
              top: $num1;
              margin-left: $num0 !important;

              ~label {
                border: $num1 solid $secondaryColor;
                padding: $num10;

                &::before {
                  content: "";
                  width: $num0;
                  height: $num0;
                  border-style: solid;
                  border-width: $num20 $num20 $num0 $num0;
                  border-color: $secondaryColor transparent transparent transparent;
                  position: absolute;
                  top: $num0;
                  left: $num0;
                }
              }
            }

            +label {
              position: relative;
              padding: $num10;
              border: $num1 solid $bordercolor;
              box-shadow: $num0 $num2 $num5 -#{$num2} rgba($black, 0.04);
              margin: $num0;
            }
          }
        }

        /* register as radio end */

        #otp {
          letter-spacing: $num20;
        }
      }

      .otp-sent {
        font-size: $num10;
        text-align: left;

        span {
          color: $secondaryColor;
        }
      }

      .reg-steps {
        position: relative;
        overflow: hidden;
        display: flex;

        .step {
          flex: $num0 $num0 auto;
          width: $per100;
        }
      }

      .otp-counter {
        font-size: $num12;
        color: $primaryColor;
      }

      .previous-next {
        button {
          svg {
            font-size: $num16;
            color: $white;
          }

          &.btn-prev {
            svg {
              margin-right: $num5;
            }
          }

          &.btn-next {
            svg {
              margin-left: $num5;
            }
          }
        }
      }
    }

    p.common_head_desc {
      margin-bottom: $num15;
      text-align: center;
      font-size: $num12;
    }
  }

  .forgot-link {
    font-size: $num12;
    margin: $num15 $num0 $num0;

    a {
      margin: $num0 $num5;
    }
  }

  a {
    color: $secondaryColor;

    &:hover {
      color: $primaryColor;
    }
  }
}

/*  login end  */

/* footer */

footer {
  font-size: $num12;
  z-index: 9;
  position: relative;
  background: $white;
  max-height: $num49;
  border-top: $num1 solid $bordercolor;
  padding: $num15 $num0;
  width: $per100;
  z-index: 9;
  @include flex(flex, center, unset);

  a {
    color: $secondaryColor;
  }
}

footer.intro_footer {
  padding: $num15 $num0;
  width: $per100;
  z-index: 99;
  border-top: $num1 solid $bordercolor;
  background: $white;
  font-size: $num12;

  a {
    color: #3905c0;
  }
}

/* footer end */

/* introduction */
.introduction-main {
  .survey-image {
    margin-bottom: $num10;
    text-align: center;

    img {
      padding: $num6;
      border-radius: $per10;
      height: $num120;
      width: $num120;
      border: $num1 solid $bordercolor;
      background-color: $bodyBGColor;
      box-shadow: $num0 $num0 $num10 rgba($black, 0.1);
    }
  }

  h3 {
    .survey-date {
      display: block;
      font-size: $num12;
    }
  }

  .intro-top {
    margin-bottom: $num25;
    text-align: center;

    .intro-survey-name {
      margin-bottom: $num4;
    }

    .intro-survey-by {}

    .intro-survey-date {
      margin-top: $num12;

      >span {
        strong {
          font-weight: $semibold;
        }

        &.start_date {
          strong {
            color: $S-Completed;
          }
        }

        &.end_date {
          strong {
            color: #dc3545;
          }

          margin-left: $num15;
        }
      }

      span.upcoming_info {
        position: absolute;
        right: $num50;
        top: $num15;
        color: $white !important;
        background: #17a2b8;
        padding: $num0 $num10 $num0 $num20;
        border-radius: $num3;

        &:before {
          content: "";
          position: absolute;
          width: $num6;
          height: $num6;
          background-color: $white;
          top: $num8;
          left: $num10;
          border-radius: $per100;
        }
      }
    }
  }

  .intro-survey-status {
    position: absolute;
    right: $num0;
    top: $num0;

    &:before {
      content: "";
      position: absolute;
      width: $num6;
      height: $num6;
      background-color: $white;
      top: $num11;
      left: $num10;
      border-radius: $per100;
    }

    span {
      color: $white;
      padding: $num3 $num10 $num3 $num20;
      border-radius: $num3;
      box-shadow: $num0 $num1 $num3 $num0 rgba($black, 0.2);
      display: block;

      &.open-survey {
        background-color: $activeSurvey;
      }

      &.close-survey {
        background-color: $closeSurvey;
      }

      &.comming-survey {
        background-color: #17a2b8;
      }

      &.draft-survey {
        background-color: $draftSurvey;
      }
    }
  }

  .intro-survey-note {
    font-weight: $semibold;
    text-align: center;
    margin-top: $num25;
    font-size: $num16;

    &.close-survey {
      color: $closeSurvey;
    }

    &.comming-survey {
      color: #17a2b8;
    }

    &.draft-survey {
      color: $draftSurvey;
    }
  }

  .intro_block {
    margin-top: $num15;

    img {
      display: block;
      margin: $num10 auto;
      width: auto;
      height: auto;
      max-width: $per100;
      max-height: $num300;
    }

    a {
      color: $secondaryColor;
      cursor: pointer;
      text-decoration: underline;

      span {
        color: $secondaryColor !important;
      }
    }

    &>p {
      margin: $num0 $num0 $em1;
    }

    &>p:empty {
      min-height: $num18;
    }
  }
}

/* survey-main */

.survey-wrapper {
  .left-panel {
    top: $num0;
    padding: $num15;
  }
}

.survey-main {
  .survey-wrapper {
    .question_boxes {
      border-radius: $num10;
      position: relative;
      box-shadow: rgba(149, 157, 165, 0.2) $num0 $num8 $num24 !important;
    }

    .question-main {
      margin: $num10 $num0 $num0;

      small {
        font-weight: $regular;
        font-size: $num11;
      }

      .question-number {
        font-size: $num13;
        border: $num1 solid $bordercolor;
        border-radius: $num5;
        display: block;
        width: auto;
        height: auto;
        position: absolute;
        left: $num15;
        top: -#{$num11};
        background: $white;
        font-weight: $bold;
        padding: $num5 $num15;
      }

      .k-dropdown {
        width: $per100;
        height: $num45;
        box-shadow: $num0 $num2 $num2 $num0 rgba($black, 0.03);
        border: none;

        .k-dropdown-wrap {
          background-color: transparent;
          box-shadow: none;
          border: $num1 solid $bordercolor;
          border-radius: $num0;
          font-size: $num12;

          &.k-state-focused {
            box-shadow: $num0 $num20 $num30 $num0 rgba($black, 0.08);
            border-color: $secondaryColor;
          }

          .k-input {
            height: $per100;
          }
        }
      }
    }
  }
}

/* create survey */

.add-question-main,
.profile-main,
.survey-settings-main {
  position: relative;

  .left-panel {
    &.slideLeft {
      .accordion {
        .accordion-item {
          h2 {
            .accordion-button {
              font-size: $num0;
              padding: $num20;
            }
          }

          .accordion-collapse {
            .accordion-body {

              .btn,
              .settings-items {
                font-size: $num0;
                display: grid;
                place-content: center;

                svg {
                  margin-right: $num0;
                  border-right: $num0;
                  padding: $num0 $num5;
                }
              }
            }
          }
        }
      }
    }

    /* view after collapse end */
    .accordion {
      .accordion-item {
        h2 {
          .accordion-button {
            color: $white;
            border-radius: $num0;
            text-transform: uppercase;
            background-color: $secondaryColor;
            font-size: $num12;
            padding: $rem1 $num10;

            &:hover {
              background-color: $primaryColor;
            }

            &:not(.collapsed) {
              svg {
                transform: rotate(180deg);
              }
            }

            &:after {
              display: none;
            }

            &.collapsed {
              margin-bottom: $num5;
            }

            svg {
              font-size: $num22;
              transition: all 0.3s;
              position: absolute;
              right: $num15;
            }
          }
        }

        .accordion-collapse {
          .accordion-body {
            padding: $num0;

            .btn,
            .settings-items {
              @extend .leftButtons;

              &:hover {
                background-color: $white;
                color: $secondaryColor;
                box-shadow: $num0 $num0 $num20 $num0 rgba($black, 0.11);
              }

              &.btn-added {
                background-color: #f8f9fa;
                opacity: 0.3;
                pointer-events: none;

                svg {
                  &.field-add {
                    display: none;
                  }

                  &.field-added {
                    display: block;
                  }
                }
              }

              svg {
                position: relative;
                margin-right: $num10;
                padding-right: $num10;
                font-size: $num30;
                border-right: $num1 solid $bordercolor;

                &.field-add {
                  display: block;
                }

                &.field-added {
                  display: none;
                }
              }

              span {
                svg {
                  position: relative;
                  left: $num0;
                  top: $num0;
                  transform: none;
                  font-size: $num15;
                  margin-right: $num2;
                  vertical-align: text-bottom;
                  right: $num0;
                }
              }
            }

            .survey-setting-left {
              max-height: calc(#{$vh100} - #{$num211});
              overflow-y: auto;

              button {
                flex-direction: column;
                align-items: flex-start;
                padding: $num15 $num10;

                h6 {
                  font-size: $num12;
                  color: $grey;
                  margin: $num0 $num0 $num5;
                  transition: all 0.3s;
                }

                p {
                  font-size: $num11;
                  color: $primaryColor;
                }

                &:hover {
                  h6 {
                    color: $secondaryColor;
                  }
                }
              }

              /* width */
              &::-webkit-scrollbar {
                width: $num3;
              }

              /* Track */
              &::-webkit-scrollbar-track {
                background: $white;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: $lightGrey;
                border-radius: $num10;
              }

              /* Handle on hover */
              &::-webkit-scrollbar-thumb {
                &:hover {
                  background: $grey;
                }
              }
            }
          }
        }
      }
    }
  }

  .right-panel {
    %removePsuedo {
      width: $num0;
      height: $num0;
      border-style: solid;
      position: absolute;
      right: $num0;
      content: "";
      border-color: transparent transparent transparent #a21c29;
    }

    .create-survey-form,
    .add-question-form {
      margin: $num0 auto;

      form {
        .add-edit-options {
          min-height: $num45;
          vertical-align: middle;
          @include flex(inline-flex, center, unset);
          column-gap: $num7;
        }

        .form-control {
          margin: $num0;
        }

        [class*="col"] {
          position: relative;
        }

        .drag-drop-area {
          border: $num1 dashed $bordercolor;
          padding: $num25;

          [class*="col-"] {
            &:last-child {
              margin-bottom: $num0 !important;
            }
          }

          .add-question-button {
            min-width: auto;
            background-color: $secondaryColor;
            border: none;
            color: $white;
            font-size: $num24;
            line-height: 1;
            width: $num36;
            height: $num36;
            display: grid;
            place-content: center;
            margin-inline: auto;
            transition: all 0.3s;

            &:hover {
              background-color: $white;
              color: $secondaryColor;
            }

            &::after {
              content: none;
            }
          }
        }

        .remove-field {
          position: absolute;
          right: -#{$num5};
          cursor: pointer;
          background-color: $red;
          color: $white !important;
          width: $num25;
          height: $num25;
          display: grid;
          place-content: center;
          top: $num0;
          box-shadow: -#{$num3} $num0 $num3 $num0 rgba($black, 0.15);
          font-size: $num10;
          margin: $num5 $num0 $num0;

          &::before {
            @extend %removePsuedo;
            border-width: $num5 $num0 $num0 $num5;
            top: -#{$num5};
          }

          &::after {
            @extend %removePsuedo;
            border-width: $num0 $num0 $num5 $num5;
            bottom: -#{$num5};
          }
        }
      }

      hr {
        opacity: 0.1;
      }

      button {
        min-width: unset;

        &.dropdown-toggle {
          svg {
            font-size: $num16;
          }

          &::after {
            content: none;
          }
        }
      }

      .k-input {
        height: $per100;

        &::placeholder {
          font-size: $num12;
          letter-spacing: $num0 !important;
          font-weight: $medium;
          color: $primaryColor;
        }
      }

      .k-editor {
        .k-toolbar {
          .k-button-group {
            .k-button {
              min-width: auto;

              &.k-button-icon {
                width: auto;
                height: auto;
              }
            }
          }

          .k-dropdown,
          .k-dropdowntree {
            width: auto;
            line-height: 1;

            .k-input {
              padding: 0.375rem;
            }
          }
        }
      }
    }
  }
}

.survey-controlsettings-main {
  position: relative;
  padding: $num115 $num0 $num20;
  min-height: calc(#{$vh100} - #{$num49});

  .survey-wrapper {
    display: flex;

    .right-panel {
      flex: $num0 $num0 auto;
      left: $num260;
      position: relative;
      transition: all 0.3s;
      width: calc(#{$per100} - #{$num260});

      &.right-full {
        width: calc(#{$per100} - #{$num50});
        left: $num50;

        .pager-main {
          width: calc(#{$per100} - #{$num50});
        }
      }

      .fieldbox-white {
        position: relative;
        padding: $num24;
        background-color: $white;
        margin-bottom: $num24;
        box-shadow: $num0 0.125rem 0.25rem rgba($black, 0.075) !important;

        a {
          color: $secondaryColor;

          &:hover {
            color: inherit;
          }
        }

        &:last-child {
          margin-bottom: $num0;
        }

        .form-input-main {
          margin-bottom: $num0;
        }

        .intro_block {
          margin-top: $num5;

          img {
            max-width: $per100;
            max-height: $num300;
            margin: auto;
            display: block;
          }

          p {
            margin: $num0 $num0 $em1;

            &:last-child {
              margin-bottom: $num0;
            }
          }
        }

        svg {
          vertical-align: middle;
          transition: all 0.3s;
          cursor: pointer;

          &.edit-icon {
            color: $secondaryColor;
            font-size: $num16;
            margin-left: $num5;
          }

          &.apply-icon {
            color: $green;
            font-size: $num20;
            margin-left: $num10;
          }

          &.close-icon {
            color: $red;
            font-size: $num20;
            margin-left: $num10;
          }

          &:hover {
            color: inherit;
          }
        }

        .viewtags-list {
          margin: $num0 -$num2;

          span {
            background-color: #fff;
            padding: $num4 $num12;
            border-radius: $num24;
            box-shadow: $num0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
            border: $num1 solid rgba($bordercolor, 0.6);
            margin: 2px;
            font-size: $num12;
            display: inline-block;
            vertical-align: top;
            font-weight: 500;
            color: $secondaryColor;
          }
        }
      }

      .questions-wrapper {
        max-width: $per100;
        margin: $num0 auto;
        padding-bottom: $num35 !important;
      }

      .pager-main {
        position: fixed;
        bottom: $num0;
        width: calc(#{$per100} - #{$num260});
        padding: $num50 $num0 $num10;
        background: $bodyBGColor;
        background: linear-gradient(0deg, $white $per50, transparent $per100);

        .progress {
          height: $num10;
          font-size: $num10;
          border-radius: $num10;
        }
      }

      .add_qnote {
        margin-bottom: $num10;
        font-size: $num12;
      }
    }
  }
}

.survey-wrapper {
  .question_boxes {
    border-radius: $num10;
    position: relative;
    box-shadow: rgba(149, 157, 165, 0.2) $num0 $num8 $num24 !important;
  }

  .survey-timings {
    font-size: $num13;

    span.start_date {
      color: $S-Completed;
      font-weight: $semibold;
      font-size: $num13;
    }

    span.end_date {
      color: #dc3545;
      font-weight: $semibold;
      padding-left: $num15;
      font-size: $num13;
    }
  }

  .left-panel {
    width: $num260;
    position: fixed;
    top: $num60;
    left: $num0;
    background-color: $white;
    bottom: $num0;
    padding: $num0;
    flex: $num0 $num0 auto;
    z-index: 9;
    transition: all 0.3s linear;
    overflow: hidden;

    &.slideLeft {
      width: $num50;

      .logo {
        height: auto;
        width: $per100;

        img {
          height: auto;
        }
      }

      h4 {
        margin: $num20 $num0 $num0 $num0;
        font-size: $num16;
        writing-mode: vertical-lr;
        transition: font-size 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.2s;
      }

      .short-intro,
      .survey-timings {
        display: none !important;
      }

      .short-intro {
        +span {
          display: none;
        }
      }

      .powered-by {
        display: none;
      }
    }

    .left-panel-wrape {
      max-height: calc(#{$vh100} - #{$num125});
      overflow-y: auto;

      /* scroll bar */
      /* width */
      &::-webkit-scrollbar {
        width: $num2;
        background-color: $bodyBGColor;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        border-radius: $num10;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #aaa;
      }
    }

    .logo {
      height: $num80;
      width: $per100;

      img {
        height: $per100;
      }
    }

    h4 {
      font-family: $secondaryFontFamily;
      font-weight: $medium;
      margin: $num30 $num0 $num0;
      font-size: $num22;
      transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.2s;
    }

    .survey-timings {
      margin: $num10 $num0 $num0;
      line-height: 1;
      font-size: $num12;
      transition-delay: 0.2s;

      p {
        color: $grey;
        font-weight: $medium;
        display: inline-block;
        background-color: $bodyBGColor;
        padding: $num5 $num10;
        border-radius: $num100;

        span {
          color: $primaryColor;
          margin: $num5 $num0 $num0;
          font-size: $num12;
        }

        +p {
          margin: $num5 $num0 $num0 $num0;
        }
      }
    }

    .short-intro {
      transition: font-size 0.2s cubic-bezier(0.075, 0.82, 0.165, 1) 0.2s;
      font-size: $num12;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 8;
      overflow: hidden;

      @media screen and (min-width: $xxl) {
        -webkit-line-clamp: 20;
      }

      +span {
        font-size: $num12;
      }
    }

    .powered-by {
      position: absolute;
      bottom: $num0;
      background-color: $secondaryColor;
      color: $white;
      padding: $num15;
      left: $num0;
      right: $num0;
      font-family: $secondaryFontFamily;
      font-size: $num13;

      &:hover {
        background-color: $primaryColor;

        span {
          color: $primaryColor;

          &::before {
            border-color: transparent transparent transparent $primaryColor;
          }
        }
      }

      span {
        position: absolute;
        top: $num5;
        right: $num5;
        background-color: $white;
        color: $secondaryColor;
        width: $num35;
        height: $num35;
        text-align: center;
        transition: all 0.3s;

        &::before {
          width: $num0;
          height: $num0;
          border-style: solid;
          border-width: $num35 $num0 $num0 $num35;
          border-color: transparent transparent transparent $secondaryColor;
          content: "";
          position: absolute;
          left: $num0;
          bottom: $num0;
          transition: all 0.3s;
        }

        svg {
          position: relative;
          right: -#{$num8};
          top: $num0;
          font-size: $num16;
        }
      }
    }

    .toggle-left {
      position: absolute;
      top: $num30;
      right: -#{$num15};
      font-size: $num18;
      width: $num30;
      place-content: center;
      transition: all 0.3s;
      color: $primaryColor;
      cursor: pointer;
      height: $num30;
      border-radius: $num100;
      box-shadow: $num0 $remh $rem1 rgba($black, 0.15) !important;
      background-color: $white;
      border-bottom: $num1 solid $bordercolor;
      z-index: 9999;

      &:hover {
        color: $secondaryColor;
        border-bottom-color: $secondaryColor;
      }

      svg {
        position: relative;
      }
    }

    #pageAdd {
      .accordion-body {
        max-height: calc(#{$vh100} - #{$num325});
        overflow-y: auto !important;
        overflow: hidden;

        &::-webkit-scrollbar {
          width: $num3;
        }

        &::-webkit-scrollbar-track {
          background: $white;
        }

        &::-webkit-scrollbar-thumb {
          background: $lightGrey;
          border-radius: $num10;
        }

        &::-webkit-scrollbar-thumb {
          &:hover {
            background: $grey;
          }
        }
      }
    }
  }

  .right-panel.intro_survey {
    left: $num0;
    padding: $num65 $num0 $num20 $num0;
    width: $per100;
    min-height: calc(#{$vh100} - #{$num49});
    position: relative;
  }
}

.survey-wrapper.survey_panel {
  .left-panel {
    max-height: calc(#{$vh100} - #{$num165});
    overflow-y: auto !important;
    overflow: hidden;
    top: $num90;
    display: none;

    &::-webkit-scrollbar {
      width: $num3;
    }

    &::-webkit-scrollbar-track {
      background: $white;
    }

    &::-webkit-scrollbar-thumb {
      background: $lightGrey;
      border-radius: $num10;
    }

    &::-webkit-scrollbar-thumb {
      &:hover {
        background: $grey;
      }
    }
  }

  .btn.btn-added {
    background: #f8f9fa;
    width: $per100;
    padding: $num8;
    color: $primaryColor;
    border-bottom: $num1 solid $bordercolor;
    text-align: left;
    position: relative;
    font-size: $num12;
    transition: all 0.3s;
    letter-spacing: $num1;
    font-weight: $medium;
    @include flex(flex, center, flex-start);

    svg {
      position: relative;
      border-right: $num1 solid $bordercolor;
      font-size: $num30;
      margin-right: $num10;
      padding-right: $num10;
    }
  }

  .left-panel.slideLeft {
    .btn.btn-added {
      font-size: $num0;
      display: grid;
      place-content: center;

      svg {
        margin-right: $num0;
        border-right: $num0;
        padding: $num0 $num5;
      }
    }
  }
}

.survey-controlsettings-main.full-wrapper {
  .right-panel {
    left: $num0;
    width: $per100;
  }

  .settings-main {
    padding: $num0;
    max-width: $per100;
    margin: $num0;
  }
}

/* thank you */
.thankyou-main {
  // height: 79vh;
  height: auto;
  @include flex(flex, center, center);

  .thankyou-message-card {
    text-align: center;

    .thankyou-icon {
      margin: $num0 $num0 $num15;
      color: $secondaryColor;
      font-size: $num80;
      position: relative;
      line-height: $num0;
    }

    .thankyou-message {
      h4 {
        color: $secondaryColor;
      }

      color: $secondaryColor;
      font-size: $num16;
      letter-spacing: $num1;
      font-weight: $semibold;
      padding: $num0 $per15;

      p {
        margin-bottom: 16px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .thankyou-redirect {
      margin: $num10 $num0 $num0;
    }
  }
}

.page-not-found-main {
  position: relative;
  min-height: calc(#{$vh100} - #{$num49});
  @include flex(flex, center, center);
  padding: $num100 $num0 $num40;

  .page-not-found-message {
    .error-icon {
      margin: $num0 $num0 $num15;
      color: #dc3545;
      font-size: $num125;
      position: relative;
      line-height: $num0;
    }

    h4 {
      color: $primaryColor;
      font-size: $num24;
      font-weight: $bold;
      text-transform: uppercase;
      letter-spacing: $num1;
      margin-bottom: $num10;
    }

    h5 {
      color: #a9a9a9;
    }

    p {
      letter-spacing: $num1;
      font-weight: $medium;
      font-family: "Montserrat", sans-serif;
      font-size: $num14;
      color: $primaryColor;

      a {
        color: $secondaryColor;

        &:hover {
          color: $primaryColor;
        }
      }
    }
  }
}

/*
  dashboard
*/
.dashboard-main {
  padding: $num75 $num0 $num40;
  width: $per100;
  min-height: calc(#{$vh100} - #{$num49});
  position: relative;

  .welcome-line {
    font-size: $num18;

    a {
      color: $secondaryColor;

      &:hover {
        color: $primaryColor;
      }
    }
  }

  .dashboard-details {
    margin: $num15 $num0 $num0;

    .survey-card {
      background-color: $white;
      padding: $num15;
      font-size: $num12;
      color: #888;
      @include flex(flex, center, space-between);
      height: $per100;
      box-shadow: $num0 $num2 $num8 $num0 rgba($black, 0.5);
      border-left: $num3 solid transparent;

      &.active-card {
        border-left-color: $activeSurvey;
      }

      &.closed-card {
        border-left-color: $closeSurvey;
      }

      &.comming-card {
        border-left-color: $commingSurvey;
      }

      &.draft-card {
        border-left-color: $draftSurvey;
      }

      span {
        color: $primaryColor;
        font-size: $num16;
      }
    }

    .survey-create {
      display: grid;
      background-color: $secondaryColor;
      height: $per100;
      place-content: center;
      color: $white;
      box-shadow: $num0 $num2 $num8 $num0 rgba($black, 0.15);
      border: $num1 solid $bodyBGColor;
      font-weight: $semibold;
      font-size: $num12;
      text-transform: uppercase;

      &:hover {
        background-color: $primaryColor;
      }
    }
  }

  .dashboard-details+.all-surveys {
    margin-top: $num15;
  }

  .all-surveys {
    margin: $num0;

    .dashboard-data-wrap {
      background-color: $white;
      padding: $num20;
      min-height: calc(#{$vh100} - #{$num179});
      box-shadow: rgba(149, 157, 165, 0.2) $num0 $num8 $num24 !important;

      .k-listview {
        background-color: transparent;
        border: none;
        font-family: $primaryFontFamily;

        .k-listview-content {
          overflow: visible;
        }
      }

      .k-pager-wrap {
        padding: $num0;
        font-size: $num12;
      }

      .all-survey-card {
        background-color: $white;
        padding: $num25 $num0 $num15 $num15;
        border: $num1 solid $greywhite;
        transition: all 0.3s;
        position: relative;
        display: flex;
        margin: $num0 $num0 $num20;

        &:hover {
          border-color: $secondaryColor;
        }

        .survey-title {
          font-size: $num14;
          font-weight: $semibold;
          flex-grow: 10;
          margin-top: $num5;

          .survey-dates {
            font-weight: $regular;
            font-size: $num12;
            margin: $num4 $num0 $num0;
            letter-spacing: $num1;
          }

          .cat-name {
            a {
              color: $secondaryColor;

              &:hover {
                color: inherit;
              }
            }
          }
        }

        .survey-tag {
          position: absolute;
          top: $num0;
          left: $num15;
          right: $num0;

          .survey-status {
            padding: $num5;
            color: $white;
            display: inline-block;
            line-height: 1;
            font-size: $num12;
            border-radius: $num0;

            &.open-survey {
              background-color: $activeSurvey;
            }

            &.close-survey {
              background-color: $closeSurvey;
            }

            &.comming-survey {
              background-color: $commingSurvey;
            }

            &.draft-survey {
              background-color: $draftSurvey;
            }

            +span {
              margin-left: $num5;
            }
          }

          .status-soon {
            position: absolute;
            top: $num0;
            right: $num0;
            font-size: $num10;
            font-weight: $semibold;
            color: $white;
            padding: $num3 $num5;
            border-radius: $num0 $num0 $num0 $num5;
            letter-spacing: $numh;

            &.closing_soon {
              background-color: $closeSurvey;
            }

            &.coming_soon {
              background-color: $commingSurvey;
            }
          }
        }

        .survey-data {
          padding: $num0;
          margin: $num0;
          @include flex(flex, normal, flex-end);

          >li {
            list-style: none;
            width: $num100;
            padding: $num5 $num0;
            font-size: $num14;
            line-height: 1;
            border-right: $num1 dotted $greywhite;
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            text-align: center;
            flex-direction: column;

            .data-num-icon {
              height: $num20;
              @include flex(flex, center, center);

              svg {
                font-size: $num20;
              }

              &.more-icon {
                svg {
                  font-size: $num24;
                }
              }
            }

            .data-label-text {
              display: block;
              font-size: $num12;
              margin: $num5 $num0 $num0;
            }

            a {
              color: $secondaryColor;
              font-size: inherit;
              cursor: pointer;

              &:hover,
              &:focus {
                color: $primaryColor;
              }
            }
          }

          &.survey-options {
            li {
              border: none;
              padding: $num0;
              width: $num100;
              height: $per100;
              cursor: pointer;
              transition: all 0.3s;
              @include flex(flex, center, center);
              flex-direction: column;
              position: relative;
              font-size: $num30;
              color: $secondaryColor;

              &:hover {
                color: $primaryColor;
              }

              span {
                margin: $num0;
              }

              .option-open {
                position: absolute;
                top: $num80;
                background-color: $white;
                box-shadow: $num0 $num2 $num8 $num0 rgba($black, 0.14);
                border: $num1 solid $greywhite;
                min-width: $num150;
                z-index: 9;

                &:before {
                  width: $num0;
                  height: $num0;
                  border-style: solid;
                  border-width: $num0 $num10 $num10 $num10;
                  border-color: transparent transparent $white transparent;
                  content: "";
                  position: absolute;
                  top: -#{$num10};
                  left: $per50;
                  transform: translate(-#{$per50}, $num0);
                  filter: drop-shadow($num0 -#{$num1} $num0 $bordercolor);
                }

                li {
                  font-size: $num12;
                  @include flex(flex, center, flex-start);
                  flex-direction: row;
                  padding: $num10;
                  border-bottom: $num1 solid $greywhite;
                  color: $primaryColor;
                  transition: all 0.3s;
                  font-weight: $semibold;
                  width: $per100;

                  &:last-child {
                    border: none;
                  }

                  &:hover {
                    background-color: #f9f9f9;
                    color: $secondaryColor;
                  }

                  svg {
                    margin-right: $num7;
                    font-size: $num16;
                  }
                }
              }
            }
          }
        }

        .dropdown {
          .dropdown-item svg {
            width: $num32;
            height: $num25;
            padding-top: $num3;
            padding-bottom: $num3;
          }

          button {
            width: $per100;
            display: flex;
            flex-direction: column;

            .dropdown-item svg {
              padding-top: $num0;
              padding-bottom: $num0;
            }
          }

          .dropdown-item {
            padding: $num8 $num10;
          }
        }
      }
    }

    .managesurveys-wrap {
      min-height: calc(#{$vh100} - #{$num248});
    }

    .dashboard-data-wrap.my_survey_list {
      .k-listview-content {
        display: flex;
        flex-wrap: wrap;
        margin: $num0 -#{$num7};

        &>div {
          padding: $num0 $num7;
          margin-bottom: $num14;
        }

        .survey-card-box {
          padding: $num15 $num15 $num36 $num15;
          border-radius: $num10;
          position: relative;
          background-color: $white;
          border: $num1 solid $greywhite;
          transition: all 0.3s;
          height: $per100;

          &:hover {
            border-color: $secondaryColor;
          }

          .protected-lock {
            position: absolute;
            top: $num0;
            right: $num10;
            cursor: pointer;
            color: $secondaryColor;

            svg {
              pointer-events: none;
            }
          }

          .survey-title {
            font-size: $num14;
            font-weight: $semibold;

            &>div {
              font-weight: $regular;
            }

            .org-info {
              display: flex;
              font-size: $num12;
              column-gap: $num3;
              margin-top: $num2;

              .org-name {
                color: $secondaryColor;
                cursor: pointer;

                &:hover {
                  color: inherit;
                }
              }
            }
          }

          .survey-dates {
            font-weight: $regular;
            font-size: $num12;
            margin: $num4 $num0 $num0;
            letter-spacing: $num1;

            &>span {
              @include flex(flex, center, space-between);
              padding: $num7 $num0;
              font-weight: $bold;
              border-bottom: $num1 dotted $greywhite;

              span {
                font-weight: $regular;
              }

              &:last-child {
                border: none;
              }
            }

            .cat-name {
              a {
                color: $secondaryColor;
                font-weight: $regular;

                &:hover {
                  color: inherit;
                }
              }
            }
          }

          .survey_card_footer {
            @include flex(flex, center, space-between);
            background: $secondaryColor;
            padding: $num5 $num15;
            border-radius: $num0 $num0 $num10 $num10;
            position: absolute;
            left: $num0;
            right: $num0;
            bottom: $num0;

            .survey-card-actions {
              display: flex;
              column-gap: $num4;

              a,
              &>span>button {
                background-color: transparent;
                border-radius: $num3;
                padding: $num0;
                color: $white;
                font-size: $num18;
                @include flex(flex, center, center);
                border: $num1 solid rgba($white, 0.5);
                width: $num26;
                height: $num26;

                svg {
                  font-size: inherit;
                  margin: $num0;
                }

                &:hover,
                &.continue {
                  color: $secondaryColor;
                  background-color: $white;
                }

                &.continue:hover {
                  color: $white;
                  background-color: transparent;
                }
              }

              .dropdown-menu {
                button {
                  width: $per100;
                  display: flex;
                  flex-direction: column;

                  .dropdown-item {
                    padding: $num8 $num10;

                    svg {
                      width: $num32;
                      height: $num25;
                    }
                  }
                }
              }
            }

            .survey-card-left {
              @include flex(flex, center, unset);
              column-gap: $num4;

              .scb-qnum {
                padding: $num5 $num8;
                background-color: $white;
                border-radius: $num11;
                font-size: $num10;
                line-height: 1.2;
                font-weight: $semibold;
                letter-spacing: $num1;
              }

              .scb-platform {
                background-color: $white;
                border-radius: $per50;
                font-size: $num10;
                line-height: 1.2;
                width: $num22;
                height: $num22;
                @include flex(flex, center, center);
                text-transform: uppercase;
              }
            }
          }

          &.closedsince-survey {
            &:hover {
              border-color: $greywhite;
            }

            .survey-title,
            .survey-dates {
              opacity: 0.5;
            }

            .survey_card_footer {
              background-color: $greywhite;

              .scb-qnum {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }
}

.add-question-main {
  .add-question-form {
    .question-type-box {
      position: relative;
      background-color: $white;
      padding: $num24;
      box-shadow: $num0 0.125rem 0.25rem rgba($black, 0.075) !important;

      &+.question-type-box {
        margin-top: $num24;
      }

      .other-option-box {
        .survey-question-required {
          width: calc(#{$per100} - #{$num180});
        }
      }

      .survey-question-required {
        width: calc(#{$per100} - #{$num170});
        border-left: $num1 solid #ececec;
        padding-left: $num10;
        font-size: $num12;
        @include flex(flex, center, unset);
        flex-wrap: wrap;

        label {
          font-weight: $semibold;
          margin-left: $num0;
        }

        .form-control {
          font-size: inherit;
          padding: $num0 $num10 !important;
          height: $num32;
        }

        .error-box {
          width: calc(#{$per100} - #{$num110});
          display: flex;
        }

        p {
          margin-left: $num110;
          margin-top: $num2;
        }

        svg {
          vertical-align: middle;
          transition: all 0.3s;
          cursor: pointer;

          &.edit-icon {
            color: $secondaryColor;
            font-size: $num16;
            margin-left: $num5;
          }

          &.apply-icon {
            color: $green;
            font-size: $num16;
            margin-left: $num5;
          }

          &.close-icon {
            color: $red;
            font-size: $num16;
            margin-left: $num5;
          }

          &:hover {
            color: inherit;
          }
        }
      }
    }

    .question-main {
      margin: $num0;

      small:empty {
        display: none;
      }

      .question-main-top {
        margin: $num0 $num0 $num15;
        line-height: 1;
        @include flex(flex, center, flex-start);

        .question-number {
          margin: $num0;
        }

        .question-edit {
          @include flex(inline-flex, center, flex-end);
          font-size: $num12;
          color: $grey;
          margin-left: $num10;
          padding-left: $num10;
          border-left: $num1 solid $lightGrey;

          .dropdown {
            button {
              min-width: auto;
              color: $lightGrey;
              background: transparent;
              font-weight: $medium;
              line-height: 1;

              svg {
                font-size: $num16;
                margin: $num0 $num0 $num0 $num2;
              }

              &:hover {
                color: $primaryColor;
              }
            }
          }
        }
      }

      .question-text {
        margin-top: $num10;
        @include flex(flex, stretch, space-between);
        margin-bottom: 5px;

        .input-group {
          word-break: break-word;
          display: block;

          &>div:has(.question-require-star) {
            display: inline-block;
          }

          input {
            border: $num1 solid $bordercolor;
            padding: $num10;
          }
        }

        .attach-document {
          font-size: $num20;
          line-height: $num0;
          width: $num40;
          height: auto;
          padding: $num5;
          border: $num1 solid $bordercolor;
          background: linear-gradient(180deg, $white 0, $bodyBGColor);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$white", endColorstr="$bodyBGColor", GradientType=0);
          cursor: pointer;
          transition: all 0.3s;
          display: grid;
          place-content: center;
          margin-left: -#{$num1};

          &:hover {
            background: $secondaryColor;
            color: $white;
            border-color: $secondaryColor;
          }
        }

        .question-label {
          width: $per100;
          word-break: break-all;
          cursor: pointer;
        }

        .form-control {
          padding: $num0;
          border: none;
          font-weight: $semibold;
          width: $per100;
          margin: $num0;
        }
      }
    }

    .form-control {
      box-shadow: none;
      cursor: pointer;
      height: auto;
      font-weight: $regular;
      font-size: $num14;
      line-height: 1;
      border-radius: $num0;
      padding: $num10 !important;

      &:focus {
        border: $num1 solid $secondaryColor !important;
        box-shadow: $num0 $num20 $num30 $num0 rgba($black, 0.08);
      }
    }

    input[type="file"] {
      position: absolute;
      top: $num0;
      bottom: $num0;
      left: $num0;
      opacity: $num0;
      cursor: pointer !important;
    }

    .dropdown-options-main {
      margin: $num15 $num0 $num0;

      .input-group {
        margin: $num0 $num0 $num10;

        .form-control {
          padding: $num10;
          font-size: $num12;
          color: $grey;

          &::placeholder {
            font-size: $num12;
            font-weight: $medium;
            color: $primaryColor;
            letter-spacing: $num0 !important;
          }
        }

        button {
          min-width: $num40 !important;
          margin-left: $num5 !important;
          background: transparent;
          font-size: $num16;
          z-index: 1;
          transition: all 0.3s;
        }

        .delete-option {
          border: $num1 solid $red;
          color: $red;

          &:hover {
            background-color: $red;
            color: $white;
          }
        }

        .add-option {
          border: $num1 solid $secondaryColor;
          color: $secondaryColor;

          &:hover {
            background-color: $secondaryColor;
            color: $white;
          }
        }

        .disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }
}

.add-quesstion-buttons {
  margin-top: $num25;
  @include flex(inline-flex, center, center);

  button {
    margin: $num0 $num5;
  }

  .dropdown {
    button {
      &::after {
        content: none;
      }

      svg {
        font-size: $num20;
        margin: $num0 $num0 $num0 $num2;
      }
    }
  }
}

.other-option-box {
  border-bottom: $num1 solid $bordercolor;

  &.required-switch {
    margin-bottom: -#{$num15};
  }

  .otherOption {
    margin-top: $num10;
    display: none;

    .input-group {
      margin: $num0 !important;
    }

    .input-group-text {
      font-size: $num12;
      border-radius: $num0;
      width: $num70;
      justify-content: center;
      z-index: 1;
      background-color: #f9f9f9;
      color: $grey;
    }

    .form-control {
      padding: $num10;
      font-size: $num12;
      color: $grey;

      &::placeholder {
        font-size: $num12;
        font-weight: $medium;
        color: $primaryColor;
        letter-spacing: $num0 !important;
      }
    }
  }

  .other-label {
    margin: $num15 $num0 $num0;
    line-height: 1;

    label {
      font-weight: $semibold;
      font-size: $num12;
      margin: $num0 $num0 $num5;
      letter-spacing: $num1;
    }

    .form-control {
      width: $per40;
      height: $num40;
      font-size: $num12;
      padding: $num5;
    }
  }
}

.required-switch {
  margin: $num15 $num0 $num0;
  background-color: #f9f9f9;
  padding: $num10;
  border: $num1 solid #ececec;

  .display-required {
    flex: 1;
  }
}

/* switch */

.form-switch {
  margin: $num0;
  padding: $num0;
  @include flex(flex, center, unset);

  .form-check-input {
    margin-left: $num0;
    margin-top: $num0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus {
      transform: scale(1.1);
      border-color: $secondaryColor;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2332c5ff'/%3e%3c/svg%3e");
    }

    &:checked {
      background-color: $secondaryColor;
      border-color: $secondaryColor;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");

      ~.display-required {
        display: block;
        margin-left: $num10;
        padding-left: $num10;
        font-size: $num12;
        font-weight: $regular;

        &:focus {
          background-color: $white;
          border-left-color: $secondaryColor;
        }

        &::placeholder {
          font-size: $num12;
          font-weight: $medium;
          color: $primaryColor;
          letter-spacing: $num0 !important;
        }
      }

      ~.otherOption {
        display: block;
      }
    }
  }

  label {
    font-size: $num12;
    margin-left: $num5;
    margin-right: $num10;
  }
}

/* profile page */
.profile-main {
  .slideLeft {
    .left-tabs {
      button {
        font-size: $num0;
        display: grid;
        place-content: center;

        svg {
          margin-right: $num0;
          border-right: $num0;
          padding: $num0 $num5;
        }
      }
    }
  }

  /*remove from the profile main if the same design comes again*/
  .left-tabs {
    .nav-tabs {
      border-bottom: none;
    }

    button {
      width: $per100;
      padding: $num8 $num10;
      color: $primaryColor;
      border-bottom: $num1 solid $bordercolor;
      text-align: left;
      border-radius: $num0;
      position: relative;
      font-size: $num12;
      transition: all 0.3s;
      letter-spacing: $num1;
      font-weight: $medium;

      &:hover {
        background-color: $white;
        color: $secondaryColor;
        box-shadow: $num0 $num0 $num20 $num0 rgba($black, 0.11);
      }

      &.active {
        background-color: $secondaryColor;
        color: $white;
        border-color: $bordercolor;
      }

      svg {
        position: relative;
        margin-right: $num10;
        padding-right: $num10;
        font-size: $num30;
        border-right: $num1 solid $bordercolor;
      }
    }
  }

  /*remove from the profile main if the same design comes again*/
  .left-tab-content {
    padding: $num0 $num30;
    max-width: $per90;
    margin: $num0 auto;

    .tab-content {

      .personal-details-form,
      .primary-contact-info,
      .organization-list {
        background-color: $white;
        padding: $num20;
        box-shadow: $num0 0.125rem 0.25rem rgba($black, 0.075) !important;
      }
    }
  }

  .right-full {
    .left-tab-content {
      .tab-content {
        padding: $num30;

        .row {
          --bs-gutter-x: $num30;
        }
      }
    }
  }
}

.non-edit-data {
  margin: $num0 -#{$num10};

  p {
    @include flex(flex, flex-start, flex-start);
    font-size: $num12;
    padding: $num10;
    font-weight: $medium;

    &:first-child {
      padding-top: $num0;
    }

    span {
      font-weight: $semibold;
      min-width: $num100;
      font-size: $num12;
    }
  }

  .organization-subtitles {
    font-size: $num12;
    font-weight: $bold;
    color: $primaryColor;
    text-transform: uppercase;
    letter-spacing: $num1;
  }

  .change-password {
    .form-label {
      @include flex(flex, center, space-between);
    }
  }
}

.edit-icon {
  color: $secondaryColor;
  font-size: $num12;
  cursor: pointer;
  transition: all 0.3s;
  margin-left: $num10;

  svg {
    font-size: $num16;
    vertical-align: top;
  }

  &:hover {
    color: $primaryColor;
  }

  a {
    color: $secondaryColor;
  }
}

/* sort - filter - search design */

.heading-actions {
  margin-bottom: $num20;

  .heading {
    font-size: $num20;
    line-height: 1;
    font-weight: $semibold;
    position: relative;
  }

  p {
    margin-top: $num5;
  }

  .action {
    @include flex(flex, center, flex-end);

    /* filter */
    .filter {
      @include flex(flex, center, flex-end);

      .filter-label {
        color: $grey;
        font-size: $num12;
        font-weight: $semibold;
      }

      .k-multiselect {
        width: auto;
        transition: all 0.3s;
        background-color: $white;
        border: $num1 solid $bordercolor;
        position: relative;
        height: $num30;
        font-weight: $medium;
        border-radius: $num0;
        @include action-box-bg();

        &:hover {
          color: $secondaryColor;
          border-color: $secondaryColor;
        }

        svg {
          font-size: $num18;
          margin-left: $num10;
        }

        .k-multiselect-wrap {
          overflow-y: hidden;
          background-color: transparent;
          border-radius: $num0;
          border: none;

          ul {
            margin-right: $num20;

            li {
              border: none;
              border-radius: $num0;
              padding: $num3 $num6;
              margin: $num2 $num0 $num3 $num2;
              font-size: $num12;
              background: $secondaryColor;
              color: $white;
              min-height: auto;

              .k-icon {
                font-size: $num14;
              }
            }
          }

          .k-clear-value {
            height: $per100;
            visibility: visible;
          }

          .k-searchbar {
            height: $per100;

            .k-input {
              height: auto;
            }
          }
        }
      }
    }

    .sort {
      .dropdown {
        height: $num30;
      }
    }

    div {
      +div {
        margin-left: $num10;
      }
    }

    .search-bar {
      position: relative;
      @include flex(flex, stretch, flex-end);

      input {
        width: $num0;
        transition: all 0.3s;
        padding: $num0;
        font-size: $num12;
        border: none;
        @include action-box-bg();

        &.input-expand {
          padding: $num0 $num10;
          border: $num1 solid $bordercolor;
          width: $num250;
        }

        &:focus {
          border-color: $secondaryColor;
          background: $white !important;

          +.search-icon {
            color: $secondaryColor;
            border-color: $secondaryColor;
            background: $white !important;
            z-index: 1;
          }
        }
      }

      svg {
        position: relative;
        cursor: pointer;
        transition: all 0.3s;
        color: $grey;
        width: $num30;
        height: $num30;
        padding: $num5;
        border: $num1 solid $bordercolor;
        @include action-box-bg();
        margin-left: -#{$num1};

        &.search-icon {
          border-left: none;
        }
      }
    }

    .dropdown {
      color: $grey;
      font-size: $num12;
      font-weight: $semibold;
      @include flex(flex, center, flex-end);
    }

    .action-area {
      transition: all 0.3s;
      padding: $num5;
      background-color: $white;
      border: $num1 solid $bordercolor;
      position: relative;
      margin-left: $num10;
      font-weight: $medium;
      cursor: pointer;
      @include action-box-bg();

      &:hover {
        color: $secondaryColor;
        border-color: $secondaryColor;
      }

      svg {
        font-size: $num18;
        margin-left: $num10;
      }
    }
  }
}

/* dropdown design */
.add-question-main,
.dashboard-main,
header {
  .dropdown {
    .btn {
      color: $secondaryColor;
      font-size: $num12;
      @include flex(flex, center, center);
      padding: $num0;

      &:hover {
        color: $primaryColor;
      }

      &::after {
        content: none;
      }

      svg {
        font-size: $num30;
        margin: $num0 $num0 -#{$num5};
      }
    }

    .dropdown-menu {
      background-color: $white;
      box-shadow: $num0 $num10 $num20 $num0 rgba($black, 0.1);
      border: $num1 solid $greywhite;
      z-index: 999;
      padding: $num0;
      border-radius: $num0;

      li {
        font-size: $num12;
        flex-direction: row;
        padding: $num0;
        border-bottom: $num1 solid $greywhite;
        color: $primaryColor;
        transition: all 0.3s;
        font-weight: $semibold;
        width: $per100;
        border-right: $num0;

        &:last-child {
          border: none;
        }

        .dropdown-item {
          padding: $num10;
          @include flex(flex, center, flex-start);
          color: $primaryColor;
          font-weight: $medium;
          cursor: pointer;
          letter-spacing: $num1;

          &>svg {
            margin-right: $num7;
            font-size: $num25;
            margin-top: -#{$num1};
            border-right: $num1 solid $greywhite;
            padding-right: $num7;
          }

          .sortactiveicon {
            margin-left: auto;
            padding-left: $num4;
          }

          &:hover,
          &.active {
            background-color: #f9f9f9;
            color: $secondaryColor;
          }
        }
      }
    }
  }
}

/* sweet alert style */

/* extend */
%swal2-styled {
  padding: $num10 $num20;
  text-align: center;
  margin: $num0 $num5;
  transition: 0.5s;
  background-size: $per200 auto;
  color: $white;
  border-radius: $num0;
  display: inline-block;
  border: $num1 solid $white;
  box-shadow: $num0 0.125rem 0.25rem rgba($black, 0.075) !important;
}

/* extend end */
.swal2-popup {
  width: $num500 !important;
  max-width: $per100 !important;
  padding: $num30 !important;
  border: $num1 solid $lightGrey !important;
  font-size: $num14 !important;
  border-radius: $num0 !important;
  box-shadow: $num0 $remh $rem1 rgba($black, 0.15) !important;

  .swal2-icon {
    margin: $num0 auto;
    width: $num60;
    height: $num60;

    .swal2-icon-content {
      font-size: $num40;
    }

    &.swal2-error {
      [class^="swal2-x-mark-line"] {
        top: $num30;
        width: $num32;
        height: $num4;
      }
    }

    &.swal2-success {
      [class^="swal2-success-line"][class$="tip"] {
        top: $num38;
        left: $num8;
        width: $num17;
      }

      [class^="swal2-success-line"][class$="long"] {
        top: $num30;
        right: $num6;
        width: $num40;
      }
    }
  }

  .swal2-title {
    font-size: $num16;
    font-weight: $medium;
    color: $primaryColor;
    padding: $num15 $num0 $num0;
    line-height: 1.6;
  }

  .swal2-html-container {
    font-size: $num14;
    color: $grey;
    padding: $num10 $num0 $num0;
    line-height: 1.5;
  }

  .swal2-actions {
    margin: $num15 $num0 $num0;
  }

  .swal2-styled {
    &.swal2-confirm {
      @extend %swal2-styled;
      background-image: linear-gradient(to right, $blue $per0, $lightblue $per51, $blue $per100) !important;

      &:hover {
        background-position: right center !important;
        /* change the direction of the change here */
        box-shadow: $num0 $remh $rem1 rgba($black, 0.15) !important;
      }
    }

    &.swal2-cancel {
      color: $red;
      box-shadow: none !important;
      padding: $num0 !important;
      min-width: unset !important;
      margin: $num0 $num20 !important;
      background: transparent !important;
      border: $num0 !important;

      &:hover {
        color: inherit;
      }
    }
  }
}

.swal2-radio {
  display: flex;
  flex-direction: column;

  label {
    @include flex(flex, baseline, unset);
    margin-bottom: $num20 !important;
  }
}

/* sweet alert style end */

/* settings */

.settings-items {
  @include flex(flex, center, space-between);
  padding: $num10 !important;
  height: $num48;

  span {
    width: calc(#{$per100} - #{$num50});
    word-break: break-word;
  }

  .settings-options {
    position: absolute;
    right: $num0;
    border-left: $num1 solid $bordercolor;
    top: $num5;
    bottom: $num5;
    width: $num50;
    display: grid;
    place-content: center;

    .form-control {
      background: transparent;
      border: none;
      border-radius: $num0;
      padding: $num0 $num5;
      font-size: $num12;
      text-align: center;
    }

    .form-check-input {
      width: $em3;
      height: $em1h;
    }
  }
}

/* loader */
center.loader_center {
  min-height: calc(#{$vh100} - #{$num49});
  @include flex(flex, center, center);
  flex-direction: column;
  padding-top: $num59;
}

.loading {
  position: fixed;
  inset: $num0;
  background-color: rgba($bodyBGColor, 0.87);
  @include flex(flex, center, center);
  flex-direction: column;
  z-index: 99999;
}

.organizatio-primary {
  margin: $num10 $num10 $num0;
  font-weight: $bold;
  background-color: $bodyBGColor;
  padding: $num10;
  color: $grey;
  letter-spacing: $num1;
}

/* -.- modal -.- */
.modal {
  .modal-content {
    border: none;
    border-radius: $num0;

    form {
      .form-input-main {
        &:last-child {
          margin-bottom: $num0;
        }
      }
    }
  }
}

/* -.- edit organization -.- */

.edit-organization {
  .left-panel {
    &.slideLeft {
      .edit-organization-left {
        .btn {
          font-size: $num0;
          display: grid;
          place-content: center;

          svg {
            margin-right: $num0;
            border-right: $num0;
            padding: $num0 $num5;
          }
        }
      }
    }

    .edit-organization-left {
      .btn {
        @extend .leftButtons;

        &:hover {
          background-color: $white;
          color: $secondaryColor;
          box-shadow: $num0 $num0 $num20 $num0 rgba($black, 0.11);
        }

        &.active {
          background-color: $secondaryColor;
          color: $white;
          border-color: $bordercolor;
        }

        svg {
          position: relative;
          margin-right: $num10;
          padding-right: $num10;
          font-size: $num30;
          border-right: $num1 solid $bordercolor;
        }
      }
    }
  }

  .edit-org-form,
  .organization-primary,
  .card-history-form {
    background-color: $white;
    padding: $num20;
    box-shadow: $num0 0.125rem 0.25rem rgba($black, 0.075) !important;
  }

  /* -.- organization form -.- */
  .organization-form {
    max-width: $per90;
    margin-inline: auto;

    .edit-org-form {
      display: flex;

      form {
        flex: 1;
      }

      .organization-logo {
        width: $num100;
        height: $num100;
        position: relative;

        label {
          width: $per100;
          height: $per100;
          background-color: #f2f2f2;
          border-radius: $per100;
          font-weight: $semibold;
          color: $bordercolor;
          overflow: hidden;
          position: relative;

          img {
            height: $per100;
            object-fit: cover;
            width: $per100;
          }

          .edit-image-icon {
            position: absolute;
            bottom: $num0;
            left: $num0;
            right: $num0;
            text-align: center;
            background: $secondaryColor;
            line-height: $num0;
            padding: $num4 $num0 $num5;
            font-size: $num15;
            color: $white;
          }
        }

        input {
          position: absolute;
          top: $num0;
          left: $num0;
          height: $per100;
          border-radius: $per100;
          opacity: $num0;
        }
      }
    }

    .main-edit-box {
      +.main-edit-box {
        margin-top: $num50;
      }
    }

    .organization-info {
      margin-left: $num20;

      button {
        margin-top: $num15;
      }
    }

    /* -.- card-history-form -.- */
    .card-history-form {
      p {
        color: $lightGrey;
      }
    }

    /* card details */
    .card-detail {
      background-image: linear-gradient(135deg, #32b3ff 1.8%, rgba(22, 255, 245, 0.6) 97.1%);
      display: block;
      border-radius: $num15;
      padding: $num15 $num20 $num20;
      line-height: 1;
      position: relative;
      overflow: hidden;

      &::before {
        content: "card";
        text-transform: uppercase;
        position: absolute;
        bottom: -#{$num20};
        right: -#{$num50};
        font-size: $num115;
        font-weight: $bolder;
        letter-spacing: $num5;
        background: linear-gradient(38deg, #70e3ff $per0, #64daf5 $per50, rgb(107, 246, 245) $per100);
        -webkit-text-fill-color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
      }

      .payment-details {
        color: $white;
        text-align: right;
        font-size: $num22;
        font-weight: $bold;
        text-shadow: $num0 $num1 $num1 rgba($black, 0.15);
        @include flex(flex, center, space-between);
      }

      .card-chip {
        margin-top: $num5;

        svg {
          font-size: $num42;
          filter: drop-shadow(0 $num2 $num1 rgba($black, 0.06));

          path {
            fill: $white;
          }

          g {
            path {
              fill: $secondaryColor;
            }
          }
        }
      }

      form {
        background-color: transparent;
        padding: $num0;
        box-shadow: none !important;

        .row {
          --bs-gutter-x: $num15;
        }

        .form-group {
          margin-top: $num15;
          position: relative;
        }

        .btn-grad {
          border-radius: $num10;
          color: $secondaryColor;
          letter-spacing: $num1;
          font-weight: $regular;
          background: rgba($white, 0.8);
          box-shadow: $num0 $num8 $num32 $num0 rgba(31, 38, 135, 0.37);
          backdrop-filter: blur($num10);
          -webkit-backdrop-filter: blur($num10);
          border-radius: $num10;
          border: $num1 solid rgba($white, 0.18);
        }
      }
    }
  }
}

.title-edit {
  @include flex(flex, center, space-between);
  border-bottom: $num1 solid #dee2e6 !important;
  margin-bottom: $num15;
  padding-bottom: $num10;

  .organization-name {
    margin-bottom: $num0;
  }
}

/* -.- kendo dropdown list design -.- */
.filter-multiselect-popup {
  width: max-content !important;

  .k-popup {
    background-color: $white;
    box-shadow: $num0 $num10 $num20 $num0 rgba($black, 0.1);
    border: $num1 solid $greywhite;
    z-index: 99;
    padding: $num0;
    border-radius: $num0;
    min-width: auto;

    .k-list-scroller {
      .k-list {
        .k-item {
          padding: $num10;
          @include flex(flex, center, flex-start);
          color: $primaryColor;
          font-weight: $medium;
          cursor: pointer;
          letter-spacing: $num1;
          font-size: $num12;
          border-bottom: $num1 solid $greywhite;
          width: $per100;

          &.k-state-selected {
            color: $white;
            background-color: $secondaryColor;
            box-shadow: none;

            &:hover {
              background-color: $secondaryColor;
              color: $white;
            }
          }

          &.k-state-focused {
            box-shadow: none;
          }

          &:hover {
            background-color: #f9f9f9;
            color: $secondaryColor;
          }
        }
      }
    }
  }
}

/* secondary header */
.secondary-header {
  position: fixed;
  top: $num61;
  left: $num0;
  right: $num0;
  z-index: 999;
  max-height: $num60;
  background-color: $white;

  .create-survey-flow {
    @include flex(flex, center, space-between);
    column-gap: $num10;
    padding: $num5 $num0;

    .left-menu {
      ul {
        @include flex(flex, center, flex-start);
        flex-wrap: wrap;
        padding: $num0;
        margin: $num0;

        li {
          list-style: none;

          a {
            font-size: $num11;
            text-transform: uppercase;
            letter-spacing: $num1;
            font-weight: $medium;

            &.active {
              color: $secondaryColor;
              font-weight: $bold;
            }
          }

          +li {
            margin-left: $num25;
          }
        }
      }
    }

    .right-menu {
      .btn-grad {
        // padding: $num5 $num30;

        +.btn-grad {
          margin-left: $num10;
        }
      }
    }

    .add-question-right {
      @include flex(flex, center, flex-end);
      flex-wrap: wrap;

      .total-question-count {
        margin-right: $num20;
        font-size: $num11;
        text-transform: uppercase;
        font-weight: $bold;
        letter-spacing: $num1;
        position: relative;
        padding-right: $num20;

        &::after {
          content: "";
          position: absolute;
          top: $per50;
          height: #{$num50 + $num4};
          margin-top: -$num27;
          right: $num0;
          border-left: $num1 solid #dee2e6;
        }

        span {
          color: $secondaryColor;
        }
      }

      .publish-dropdown-main {
        position: relative;

        .publish-toggle {
          &:after {
            content: "";
            display: inline-block;
            margin-left: $num4;
            vertical-align: baseline;
            border-left: $num7 solid transparent;
            border-right: $num7 solid transparent;
            border-top: $num7 solid;
          }
        }

        .publish-dropdown {
          position: absolute;
          right: $num0;
          min-width: 290px;
          padding: $num12 $num16;
          background-color: #fff;
          box-shadow: $num0 $num4 $num12 rgba(0, 0, 0, 0.1);
          top: 100%;
          margin-top: $num7;

          .k-radio-item {
            gap: 6px;
          }

          label.k-radio-label {
            font-weight: $semibold;
          }

          .unpublish-btn {
            margin: 0 !important;
            background-color: $red;
            color: $white;
            padding: $num6 $num12;

            &:hover,
            &:focus {
              background-color: rgba($red, 0.8);
            }
          }
        }
      }
    }
  }
}

.survey-wrapper {
  .left-panel {
    top: $num115;
    max-height: calc(#{$vh100} - #{$num115});
    overflow-y: auto !important;
    overflow: hidden;

    /* width */
    &::-webkit-scrollbar {
      width: $num3;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: $white;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $lightGrey;
      border-radius: $num10;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb {
      &:hover {
        background: $grey;
      }
    }
  }
}

/* edit survey title in add question */
.edit-title-language {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .form-label {
    color: $primaryColor;
    font-weight: $semibold;
    font-size: $num14;
  }

  span.total_page {
    border-radius: $per100;
    display: inline-block;
    margin: $num0 $num5;
    font-size: $num14;
    font-weight: $semibold;
    width: $num25;
    height: $num25;
    line-height: $num25;
    text-align: center;
  }

  .survey-title {
    font-size: $num14;
    text-transform: capitalize;
    @include flex(flex, center, unset);

    svg {
      vertical-align: middle;
      transition: all 0.3s;
      cursor: pointer;

      &.edit-icon {
        color: $secondaryColor;
        font-size: $num16;
        margin-left: $num5;
      }

      &.apply-icon {
        color: $green;
        font-size: $num20;
        margin-left: $num10;
      }

      &.close-icon {
        color: $red;
        font-size: $num20;
        margin-left: $num10;
      }

      &:hover {
        color: inherit;
      }
    }

    .form-control {
      border-radius: $num0;
      border: $num1 solid $bordercolor;
      box-shadow: $num0 $num2 $num2 $num0 rgba($black, 0.03);
      font-size: $num13;
      padding: $num0 $num15;
      font-weight: $medium;
      height: $num45;
      width: $num250;

      &::placeholder {
        font-size: $num12;
        letter-spacing: $num0 !important;
        font-weight: $medium;
        color: $primaryColor;
      }

      &:focus {
        box-shadow: $num0 $num20 $num30 $num0 rgba($black, 0.08);
      }
    }
  }

  .survey-language {
    background-color: $bodyBGColor;
    @include flex(flex, center, flex-end);
    flex-wrap: wrap;

    span {
      font-size: $num12;
      color: $primaryColor;
      font-weight: $medium;
    }

    .dropdown {
      .btn {
        color: $primaryColor;
        font-weight: $semibold;

        svg {
          font-size: $num17;
          margin: $num0 $num0 $num0 $num2;
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

/* settings */
.settings-main {
  margin: $num0 auto;

  .survey-settings-title {
    font-size: $num15;
    margin: $num35 $num0 $num15;
    line-height: 1;
    text-transform: uppercase;
    letter-spacing: $num1;
    font-weight: $bold;
  }

  .settings-box {
    position: relative;
    padding: $num24;
    background-color: $white;
    margin-bottom: $num24;
    box-shadow: $num0 0.125rem 0.25rem rgba($black, 0.075) !important;

    &:last-child {
      margin-bottom: $num0;
    }

    .setting-description {
      width: $per100;
      position: relative;
      @include flex(flex, center, space-between);

      +.setting-description {
        margin-top: $num15;
        border-top: $num1 solid $bordercolor;
        padding-top: $num15;
      }

      .form-switch {
        width: $per100;

        .form-check-input {
          width: $num36;
          border-radius: $num36;
          height: $num18;
        }

        .k-switch {
          position: absolute;
          top: $num0;
          right: $num0;
        }
      }

      .setting-info {
        flex: $num0 $num0 auto;
        width: calc(#{$per100} - #{$num100});

        .titile-line {
          font-size: $num12;
          font-weight: $bold;
          letter-spacing: $num1;
          margin: $num0 $num0 $num8;
          line-height: 1;
        }

        p {
          font-size: $num12;
          font-weight: $regular;
          color: $grey;
          line-height: 1.5;
        }

        .settings-input-text {
          margin-top: $num10;
          @include flex(flex, flex-end, unset);

          &:has(.field-full) {
            display: block;

            .settings-actions {
              margin-bottom: $num0;

              span {
                margin-left: $num0;
                margin-right: $num10;
              }
            }
          }

          .k-form-field {
            margin-top: $num0;
            line-height: 1;
          }

          .answer_block {
            color: $secondaryColor;
            font-weight: $semibold;
            display: inline-block;
          }

          .answer_block.shadow {
            width: auto;
            height: $num25;
            text-align: center;
            border-radius: $num3;
            line-height: $num25;
            box-shadow: rgba($black, 0.05) $num0 $num6 $num24 $num0, rgba($black, 0.08) $num0 $num0 $num0 $num1 !important;
            margin-right: $num10;
            padding: $num0 $num10;
          }

          .settings-actions {
            font-size: $num18;
            margin-bottom: $num10;

            span {
              cursor: pointer;
              margin-left: $num10;

              &:hover {
                color: inherit;
              }
            }
          }

          &.password-field {
            .k-form-field {
              .k-input {
                padding-right: $num30;
                width: $num150;
              }

              .show-hide-password {
                position: absolute;
                top: $num16;
                right: $num15;

                svg {
                  position: absolute;
                  top: $num0;
                  right: $num0;
                  cursor: pointer;
                  font-size: $num15;
                }
              }
            }

            .settings-actions {
              margin-bottom: $num26;
            }
          }

          &.link-field {
            .k-form-field {
              width: #{$num500 + $num50};
              max-width: calc(#{$per100} - #{$num60});
            }

            .settings-actions {
              margin-bottom: $num26;
            }
          }
        }

        .k-form-field {
          .form-label {
            font-size: $num12;
            margin: $num0 $num0 $num3;
            display: block;
            font-weight: $semibold;
          }
        }

        span.edit_button {
          transition: all 0.3s;
          padding: $num5;
          border: $num1 solid $secondaryColor;
          color: $white;
          background-color: $secondaryColor;
          position: absolute;
          top: $num0;
          right: $num0;
          font-size: $num13;
          line-height: 1;
          font-weight: $medium;
          cursor: pointer;
        }
      }

      .setting-actions {
        padding: $num15;
        border-left: $num1 solid $bordercolor;
        width: $num100;
        @include flex(flex, center, center);

        &.checkBoxes {
          flex-direction: column;
          align-items: flex-start;
          padding: $num0;

          .form-check {
            min-height: auto;
            padding-left: $num20;
            margin-bottom: $num0;
            @include flex(flex, center, unset);
            position: relative;

            +.form-check {
              margin-top: $num10;
            }

            .form-check-input {
              visibility: hidden;
              margin: $num0;

              &:checked {
                ~label {
                  &::before {
                    border-color: $secondaryColor;
                  }

                  &::after {
                    background-color: $secondaryColor;
                  }
                }
              }
            }

            .form-check-label {
              font-size: $num12;
              font-weight: $medium;
              line-height: 1;
              margin: $num0;

              &::before {
                content: "";
                width: $num16;
                height: $num16;
                margin: $num0;
                position: absolute;
                border: $num1 solid $bordercolor;
                left: $num10;
                top: $num0;
                border-radius: $num3;
              }

              &::after {
                content: "";
                width: $num12;
                height: $num12;
                margin: $num0;
                position: absolute;
                left: $num12;
                top: $num2;
                border-radius: $num2;
              }
            }
          }
        }

        .form-control {
          padding: $num5;
          line-height: 1;
          text-align: center;
          border-radius: $num0;
          font-size: $num12;
          font-weight: $medium;
        }

        span {
          cursor: pointer;
          font-size: $num20;
        }
      }

      .setting-actions.survey_settings {
        .settings-input-text {
          font-size: $num13;
          text-align: center;
          padding: $num0 !important;

          label {
            margin-left: $num0 !important;
            margin-right: $num0 !important;
            @include flex(flex, center, unset);

            input {
              margin-right: $num3;
            }
          }

          .k-form-field {
            margin-bottom: $num10;
            position: relative;

            .k-textarea {
              resize: none;
            }

            .k-form-hint {
              right: $num8 !important;
              bottom: $num8;
            }

            .k-textbox-container {
              width: $per100 !important;

              .k-input {
                padding: $num0 $num5;
                height: $per100;
              }

              .k-label {
                font-size: $num14;
                left: $num5;
              }
            }

            .k-list-horizontal {
              .k-radio-item {
                margin: $num0 -#{$num5} $num0 $num0 !important;
                display: flex;

                input {
                  margin-right: $num3;
                }
              }
            }
          }
        }
      }
    }
  }
}

.edit-mode-actions {
  @include flex(flex, center, flex-end);
  flex-wrap: wrap;

  button {
    margin: $num0 $num0 $num0 $num5;
    font-size: $num12;
    text-transform: uppercase;
    letter-spacing: $num1;
    padding: $num5;
    min-width: $num80 !important;
    font-weight: $semibold;
  }
}

.normal-mode {
  .question-main-top:has(.question-image-icon) {
    .question-visible-icon {
      margin-right: $num80;
    }

    .question-duplicate-icon {
      margin-right: $num80;
    }

    .demo-question-duplicate-icon {
      margin-right: $num80;
    }
  }

  .question-main-top:has(.logic-btn) {
    .question-image-icon {
      margin-right: #{$num165 + $num2};
    }

    .question-visible-icon {
      margin-right: $num165;
    }

    .question-duplicate-icon {
      margin-right: $num165;
    }

    .demo-question-duplicate-icon {
      margin-right: $num165;
    }
  }

  .question-main-top:has(.logic-btn):has(.question-image-icon) {
    .question-visible-icon {
      margin-right: #{$num200 + $num3};
    }
  }

  .question-main-top:has(.logic-btn):has(.question-image-icon) {
    .question-duplicate-icon {
      margin-right: #{$num200 + $num3};
    }

    .demo-question-duplicate-icon {
      margin-right: #{$num200 + $num3};
    }
  }

  .question-image-icon {
    transition: all 0.3s;
    border: $num1 solid $secondaryColor;
    color: $secondaryColor;
    position: absolute;
    top: $num6;
    right: $num30;
    font-size: $num19;
    line-height: 1;
    margin-right: $num45;
    cursor: pointer;
    padding: $num1 $num3;
    height: $num23;
    @include flex(flex, center, unset);
    text-align: center;

    &.view-ques-icon {
      right: $num30;
      margin-right: $num0;
    }
  }

  .question-image-icon:hover {
    color: $primaryColor;
    border-color: $primaryColor;
  }

  .question-visible-icon {
    transition: all 0.3s;
    border: $num1 solid $secondaryColor;
    color: $secondaryColor;
    position: absolute;
    top: $num6;
    right: $num30;
    font-size: $num19;
    line-height: 1;
    margin-right: $num45;
    cursor: pointer;
    padding: $num1 $num3;
    height: $num23;
    @include flex(flex, center, unset);
    text-align: center;

    svg {
      pointer-events: none;
    }
  }

  .question-duplicate-icon {
    transition: all 0.3s;
    border: $num1 solid $secondaryColor;
    color: $secondaryColor;
    position: absolute;
    top: $num6;
    right: $num65;
    font-size: $num19;
    line-height: 1;
    margin-right: $num45;
    cursor: pointer;
    padding: $num1 $num3;
    height: $num23;
    @include flex(flex, center, unset);
    text-align: center;

    svg {
      pointer-events: none;
    }
  }


  .demo-question-duplicate-icon {
    transition: all 0.3s;
    border: $num1 solid $secondaryColor;
    color: $secondaryColor;
    position: absolute;
    top: $num6;
    right: $num30;
    font-size: $num19;
    line-height: 1;
    margin-right: $num45;
    cursor: pointer;
    padding: $num1 $num3;
    height: $num23;
    @include flex(flex, center, unset);
    text-align: center;

    svg {
      pointer-events: none;
    }
  }

  .question-visible-icon:hover {
    color: $secondaryColor;
    border-color: $secondaryColor;
  }

  .question-duplicate-icon:hover {
    color: $secondaryColor;
    border-color: $secondaryColor;
  }

  .demo-question-duplicate-icon:hover {
    color: $secondaryColor;
    border-color: $secondaryColor;
  }


  .question-require-star {
    font-size: $num20;
    padding: $num0 $num5 $num0 $num0;
    // margin-top: -#{$num5};
    line-height: 1;
  }

  .edit-button {
    transition: all 0.3s;
    padding: $num5;
    border: $num1 solid $secondaryColor;
    color: $white;
    background-color: $secondaryColor;
    position: absolute;
    top: $num6;
    right: $num30;
    font-size: $num12;
    line-height: 1;
    font-weight: $medium;

    &:hover {
      background-color: $primaryColor;
      border-color: $primaryColor;
      color: $white;
    }
  }

  .data-options {
    @include flex(flex, flex-start, flex-start);
    flex-wrap: wrap;
    margin: $num15 $num0 $num0;
    flex-direction: column;
    line-height: 1;

    &:empty {
      margin: $num0;
    }

    span {
      margin: $num0 $num7 $num7 $num0;
      background-color: $bodyBGColor;
      padding: $num7;
      border: $num1 solid $greywhite;
      font-size: $num12;
      letter-spacing: $num1;
      border-radius: $num6;
      word-break: break-all;
      font-weight: $medium;
    }
  }

  .rating_questions {
    span {
      margin: $num0;
      background-color: transparent;
      border: $num0;
      padding: $num0;
    }

    .group_rating_que {
      @include flex(flex, center, space-between);

      span {
        margin: $num0 $num0 $num15 $num0;
        word-break: break-all;

        &.flex-fill {
          font-size: $num12;
          letter-spacing: $num1;
          font-weight: $medium;
        }
      }

      span.k-rating {
        margin: $num0;
        flex: none;

        span.k-rating-container {
          span {
            margin: $num0;
          }
        }
      }
    }
  }

  .required-message {
    margin-bottom: $num5;
  }

  .uploadMediaImg {
    background-color: $greywhite;
    padding: $num2;
    width: $num150;
    height: $num150;
    margin: $num10 $num0 $num0;

    &:hover {
      background-color: $greywhite;
    }

    img {
      width: $per100;
      height: $per100;
      object-fit: cover;
    }
  }
}

.uploadMedia {
  position: relative;
  margin: $num10 $num0 $num0;
  background-color: $bodyBGColor;
  padding: $num3 $num3 $num3 $num10;
  display: none;
  @include flex(none, center, space-between);
  flex-wrap: wrap;
  transition: all 0.3s;

  &:hover {
    background-color: $secondaryColor;

    .questionMedia {
      color: $white;
    }
  }

  .questionMedia {
    color: $primaryColor;
    font-size: $num12;
    font-weight: $medium;
    letter-spacing: $num1;
    transition: all 0.3s;
    flex: $num0 $num0 $num105;
    cursor: pointer;

    svg {
      vertical-align: middle;
      font-size: $num15;
    }
  }

  .file-name {
    background-color: $white;
    padding: $num10;
    margin-left: $num10;
    font-size: $num12;
    font-weight: $medium;
    color: $lightGrey;
    line-height: 1;
    flex: $num0 $num0 calc(#{$per100} - #{$num115});
    cursor: default;
  }

  .remove-media {
    position: absolute;
    top: $per50;
    right: $num10;
    transform: translate(0, -#{$per50});

    svg {
      color: $red;
      cursor: pointer;
    }
  }
}

.file-prog {
  font-size: $num12;
  margin: $num5 $num0;
  @include flex(flex, center, unset);
  gap: $num5;

  .k-progressbar {
    height: $num12;
    border-radius: $num12;
    width: $per100;
    box-shadow: $num1 $num1 $num2 rgba($black, 0.2);
  }

  .k-state-selected {
    background-color: $secondaryColor;
  }
}

audio {
  width: $per100;
  height: $num45;
  margin: $num10 $num0 $num0;
}

video {
  width: $num300;
  height: auto;
  border: $num2 solid $greywhite;
  margin: $num10 $num0 $num0;
}

/* Start Feedback Form */
.dashboard-main.feedback_form {
  @include flex(flex, center, unset);
  padding: $num20 $num0;

  .form-account {
    padding: $num25;
    border: $num1 solid $greywhite;
    position: relative;

    h4 {
      text-align: center;
      margin-bottom: $num20;
    }
  }
}

/* End Feedback Form */

/* Feedback Popup */
.feeback_chat_popup {
  display: inline-block;

  .chat-btn {
    font-size: $num12;
    border: none;
    z-index: 999;
    color: $secondaryColor;
    cursor: pointer;

    svg {
      transition: all 0.9s ease;
      font-size: $num22;
      color: $white !important;
      border-radius: $num25;
      padding: $num3;
    }
  }

  .chat_popup {
    transition: 0.4s;
    width: $num320;
    bottom: $num49;
    position: fixed;
    z-index: 99999;
    overflow: hidden;
    height: calc(#{$per100} - #{$num115});
    left: $per100;

    .wrapper {
      max-height: $per100;
      overflow: auto;
      bottom: $num5;
      width: $per98;
      margin: $num0 $per1;
      position: absolute;
      line-height: 1;
      background-color: $bodyBGColor;
      border-radius: $num5;
      padding: $num0;
      text-align: left;

      box-shadow: $num0 $num6 $num24 $num0 rgba($black, 0.05), $num0 $num0 $num0 $num1 rgba($black, 0.08);

      .header {
        padding: $num13;
        background-image: linear-gradient(to right, $blue $per0, $lightblue $per51, $blue $per100);
        border-radius: $num5 $num5 $num0 $num0;
        color: $white;

        h6 {
          font-size: $num14;
          margin-top: $num3;
          margin-bottom: $num0;
        }
      }

      .chat-form {
        padding: $num15;
        padding-top: $num0;

        .k-label {
          margin-bottom: $num0;
        }

        button {
          margin-top: $num15;
        }

        textarea {
          resize: none;
          min-height: $num90;
          padding: $num15 !important;
        }

        .form-control:focus,
        .btn:focus {
          box-shadow: none;
        }

        input,
        textarea {
          border: $num1 solid $bordercolor;
          border-radius: $num0;
          box-shadow: $num0 $num2 $num2 $num0 rgba($black, 0.03);
          font-size: $num13;
          font-weight: $medium;
          height: $num45;
          padding: $num0 $num15;
          color: #212529;

          &::placeholder {
            color: $primaryColor;
          }

          &:focus {
            box-shadow: $num0 $num20 $num30 $num0 rgba($black, 0.08) !important;
            border-color: $secondaryColor !important;
          }
        }

        .k-textarea {
          border: $num0;
          padding: $num0;
          box-shadow: none !important;
          height: auto !important;
        }
      }

      span.instruction {
        font-size: $num11;
        font-weight: $regular;
      }
    }
  }

  #check {
    display: none !important;
  }
}

#check:checked~.chat-btn svg {
  pointer-events: auto;
}

#check:checked~.chat_popup {
  margin-left: -#{$num370};
}

.settings-main {
  .settings-box {
    .setting-description {
      .setting-info {
        width: $per100;

        &>.titile-line {
          line-height: 1.3;
        }

        &>.titile-line {
          width: calc(#{$per100} - #{$num70});
        }
      }
    }

    &.mb-4:last-child {
      margin-bottom: $num0 !important;
    }
  }
}

/* Alert */
.alert {
  width: auto;
  position: fixed;
  top: $num58;
  right: $num5;
  z-index: 999;

  button {
    font-size: $num12;
  }
}

/*  introduction  */

.introduction-reg-main {
  @include flex(flex, center, center);
  margin-top: $num25;

  .form-account {
    background-color: $white;
    padding: $num24 $num15;
    margin: auto;
    max-width: $per100;
    position: relative;
    width: $num370;

    .form-label+.k-form-field {
      margin-top: $num0;
    }

    .k-form-field {
      font-size: $num14;

      label.k-label-empty {
        display: none;
      }
    }

    h4 {
      position: relative;
      margin: $num0 $num0 $num12;
      padding: $num0 $num0 $num12;

      &::after {
        content: "";
        position: absolute;
        left: $num0;
        right: $num0;
        bottom: $num0;
        height: $num1;
        background: $white;
        background: -moz-linear-gradient(90deg, rgba($white, $num0) $per0, rgba($bodyBGColor, 1) $per50, rgba($white, $num0) $per100);
        background: -webkit-linear-gradient(90deg, rgba($white, $num0) $per0, rgba($bodyBGColor, 1) $per50, rgba($white, $num0) $per100);
        background: linear-gradient(90deg, rgba($white, $num0) $per0, rgba($bodyBGColor, 1) $per50, rgba($white, $num0) $per100);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="$white", endColorstr="$white", GradientType=1);
      }
    }

    form {
      img {
        width: $num80;
      }

      .checkbox {
        font-weight: $regular;

        input {
          vertical-align: middle;
        }

        small {
          font-size: $num10;
          font-weight: $regular;
        }
      }

      .form-input-main {

        /* register as radio */
        .form-check {
          min-height: auto;
          padding-left: $num0;
          margin: $num0 $num10 $num0 $num0;
          position: relative;

          input[type="radio"] {
            border-radius: $per50;
            position: absolute;
            top: $num0;
            left: $num0;
            border-radius: $num0 !important;
            margin: $num0;
            border: none;
            width: $per100;
            height: $per100;
            background-color: transparent;

            &:checked {
              width: $num12 !important;
              height: $num12 !important;
              left: auto !important;
              z-index: 9;
              top: $num1;
              left: $num0;

              ~label {
                border: $num1 solid $secondaryColor;
                padding: $num10;

                &::before {
                  content: "";
                  width: $num0;
                  height: $num0;
                  border-style: solid;
                  border-width: $num20 $num20 $num0 $num0;
                  border-color: $secondaryColor transparent transparent transparent;
                  position: absolute;
                  top: $num0;
                  left: $num0;
                }
              }
            }

            +label {
              position: relative;
              padding: $num10;
              border: $num1 solid $bordercolor;
              box-shadow: $num0 $num2 $num5 -#{$num2} rgba($black, 0.04);
              margin: $num0;
            }
          }
        }

        /* register as radio end */

        #otp {
          letter-spacing: $num20;
        }
      }

      .otp-sent {
        font-size: $num10;
        text-align: left;

        span {
          color: $secondaryColor;
        }
      }

      .reg-steps {
        position: relative;
        overflow: hidden;
        display: flex;

        .step {
          flex: $num0 $num0 auto;
          width: $per100;
        }
      }

      .otp-counter {
        font-size: $num12;
        color: $primaryColor;
      }

      .previous-next {
        button {
          svg {
            font-size: $num16;
            color: $white;
          }

          &.btn-prev {
            svg {
              margin-right: $num5;
            }
          }

          &.btn-next {
            svg {
              margin-left: $num5;
            }
          }
        }
      }
    }

    p.common_head_desc {
      margin-bottom: $num15;
      text-align: left;
      font-size: $num11;
    }

    a {
      color: $secondaryColor;

      &:hover {
        color: $primaryColor;
      }
    }
  }
}

/*
  custom pagignation
*/
.custom-pagignation {
  .pagination {
    margin-bottom: $num0;
  }

  .first-icon,
  .prev-icon,
  .next-icon,
  .last-icon {
    &.disabled {
      .page-link {
        opacity: 0.4;
      }
    }

    .page-link {
      position: relative;
      font-family: "WebComponentsIcons";
      border-radius: $num0 !important;
      color: $secondaryColor;
      font-size: $num16;
    }
  }

  .first-icon {
    .page-link {
      &::before {
        content: "\e00b";
      }
    }
  }

  .prev-icon {
    .page-link {
      &::before {
        content: "\e007";
      }
    }
  }

  .next-icon {
    .page-link {
      &::before {
        content: "\e005";
      }
    }
  }

  .last-icon {
    .page-link {
      &::before {
        content: "\e009";
      }
    }
  }

  .page-link {
    padding: $num0;
    width: $num35;
    height: $num35;
    display: grid;
    place-content: center;
    font-weight: $medium;
    color: $primaryColor;

    &:hover {
      background-color: $white;
      color: $secondaryColor;
    }

    .sr-only,
    span {
      display: none;
    }
  }

  .page-item {
    &.active {
      .page-link {
        background-color: $secondaryColor;
        border-color: $secondaryColor;
        font-size: $num14;
        color: $white;

        &:hover {
          color: $white;
        }
      }
    }
  }

  .custom-pagination-label {
    font-size: $num14;
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }
}

.org-info-popup {
  .k-window-content {
    padding: $num0;
  }

  .org-info-popup-top {
    padding: $num10 $num35 $num10 $num15;
    border-bottom: $num1 solid #dee2e6;

    h4 {
      font-size: $num20;
      margin-bottom: $num3;
    }

    .k-button-md.k-icon-button {
      position: absolute;
      right: $num8;
      top: $num10;
      padding: $num0;
      color: inherit;
      opacity: 0.5;

      .k-button-icon {
        font-size: $num20;
      }

      &:hover,
      &:focus {
        opacity: 1;
      }

      &:before,
      &:after {
        opacity: $num0;
      }
    }
  }

  .org-desc {
    padding: $num20;

    p {
      margin-bottom: 1rem;
    }
  }
}

.filter-list-top {
  @include flex(flex, center, space-between);
  min-height: $num36;
  margin-bottom: $num15;

  .left-filter {
    @include flex(flex, baseline, unset);
    column-gap: $num20;
    font-weight: $medium;

    .filter-counts {
      font-size: $num16;
    }

    .filter-clear {
      font-size: $num12;
      cursor: pointer;

      &:hover,
      &:focus {
        color: inherit;
      }
    }
  }

  .right-filter {
    @include flex(flex, center, unset);
    column-gap: $num12;

    &>button {
      padding: $num0;
      border: $num0;
      background-color: transparent;
      font-size: $num20;
      color: inherit;
      position: relative;

      .filter-num {
        width: $num16;
        height: $num16;
        @include flex(flex, center, center);
        font-size: $num10;
        background-color: $secondaryColor;
        color: $white;
        border-radius: $num18;
        line-height: 1.2;
        position: absolute;
        top: $num0;
        right: -#{$num12};
      }

      &:hover,
      &:focus {
        color: $secondaryColor;
      }
    }

    .dropdown {
      margin-left: $num6;

      button {
        border: $num2 solid $secondaryColor;
        color: inherit;
        background-color: $white;
        padding: $num3 $num12;
        border-radius: $num31;
        font-weight: $medium;

        &:hover,
        &:focus,
        &.selected {
          background-color: $secondaryColor;
          color: $white;
        }
      }
    }
  }
}

.search-input-main {
  column-gap: $num8;
  align-items: center;

  .search-close {
    font-size: $num20;
    cursor: pointer;
    line-height: $num0;
  }

  .form-control {
    border: $num1 solid $bordercolor;
    box-shadow: $num0 $num2 $num2 $num0 rgba($black, 0.03);
    font-size: inherit;
    padding: $num0 $num15;
    height: $num36;
    border-radius: $num36;
    min-width: $num500;

    &::placeholder {
      color: $primaryColor;
    }

    &:focus {
      box-shadow: $num0 $num20 $num30 $num0 rgba($black, 0.08);
      border-color: $secondaryColor;
    }

    &:disabled {
      background-color: $bodyBGColor;
    }
  }
}

.filter-overlay {
  position: fixed;
  inset: $num0;
  background: $black;
  opacity: 0.1;
  z-index: 1;
}

.filter-main {
  position: fixed;
  top: $num61;
  right: -#{$num600};
  background-color: $white;
  width: $num400;
  max-width: calc(#{$per100} - #{$num35});
  z-index: 10;
  bottom: $num0;
  padding-bottom: $num49;
  box-shadow: -#{$num2} $num0 $num6 rgba($black, 0.1) !important;
  transition: 0.5s ease-in-out;

  &.filter-show {
    right: $num0;
  }

  .filters-close {
    background-color: $secondaryColor;
    padding: $num0;
    border: $num0;
    position: absolute;
    left: -#{$num32};
    color: $white;
    width: $num32;
    height: $num32;
    font-size: $num20;
    line-height: 1;
    @include flex(flex, center, center);
    top: $num70;
    border-radius: $num32 $num0 $num0 $num32;
  }

  .filter-top {
    padding: $num15 $num15;
    @include flex(flex, center, space-between);
    background-color: $secondaryColor;

    h4 {
      margin: $num0;
      font-size: $num18;
      color: $white;
      letter-spacing: $num1;
    }

    .filters-reset {
      background-color: transparent;
      padding: $num0;
      border: $num0;
      color: white;
      font-weight: $semibold;
      text-transform: capitalize;
      border-radius: $num12;
      font-size: $num14;
      letter-spacing: $num1;

      &:hover {
        color: inherit;
      }
    }
  }

  .filter-inner {
    height: $per100;
    overflow-x: hidden;
    overflow-y: auto;
    padding: $num20 $num15;

    .filter-box {
      font-size: $num12;

      &+.filter-box {
        margin-top: $num25;
      }

      .filter-label {
        font-weight: $bold;
        letter-spacing: $num1;
      }

      .k-input-md {
        font-size: inherit;
        margin-top: $num4;
        border-radius: $num32;
      }

      .filter-date-box {
        display: flex;
        column-gap: $num10;

        label {
          font-size: $num10;
          font-weight: $medium;
          letter-spacing: $num1;
        }

        .k-input-md {
          height: $num32;
          margin-top: $num0;

          .k-button-solid-base {
            background-color: transparent;
            border: $num0;
            padding-right: $num12;
          }
        }
      }

      .filter-options {
        display: flex;
        column-gap: $num4;
        margin-top: $num8;

        .filter-check {
          position: relative;

          .k-checkbox-label {
            background-color: $white;
            border: $num1 solid $secondaryColor;
            padding: $num6 $num8;
            margin: $num0;
            @include flex(flex, center, center);
            font-size: $num12;
            border-radius: $num14;
            font-weight: $medium;
            line-height: 1.15;
            height: $num28;
            min-width: $num40;
          }

          input.k-checkbox {
            display: none;

            &:checked~.k-checkbox-label {
              background-color: $secondaryColor;
              color: $white;

              &:after {
                content: "\e118";
                font-family: "WebComponentsIcons";
              }
            }
          }
        }
      }

      .k-slider-horizontal {
        width: $per100;
        margin-top: $num4;
        margin-bottom: $num10;

        .k-slider-track,
        .k-slider-selection {
          height: $num6;
          margin-top: -#{$num3};
        }

        .k-draghandle {
          width: $num18;
          height: $num18;
          border-color: $secondaryColor;
          background-color: $secondaryColor;
          box-shadow: none;

          &:before {
            // content: attr(label-value);
            content: attr(aria-valuenow);
            color: $primaryColor;
            position: absolute;
            left: $per50;
            top: $per100;
            line-height: 1;
            font-size: $num12;
            font-weight: $semibold;
            transform: translateX(-#{$per50});
            margin-top: $num2;
          }
        }

        .k-slider-selection {
          background-color: $secondaryColor;
        }
      }

      .k-multiselect.k-input-md {
        min-height: $num32;
      }
    }

    .filter-btns {
      text-align: center;
      margin-top: $num36;
    }
  }
}

.k-multiselect.k-input.form-control {
  padding: 3px $num0;
  min-height: 45px;
  height: auto !important;

  .k-input-values {
    padding: $num0 15px;
    gap: 3px;

    input {
      margin: $num0;
      height: auto;
      padding: $num0;
    }
  }
}

.k-input-md .k-input-inner,
.k-picker-md .k-input-inner {
  padding: $num10 15px;
}

.k-chip {
  font-size: inherit;
  font-family: inherit;
  border: $num0;
  border-radius: $num12;
  color: $white;
  background-color: $secondaryColor;
}

.k-chip-content {
  padding: $num2 $num4;
  margin: -#{$num2} $num0 !important;
}

header.inner_header {
  + {
    .wrapper {
      padding-top: $num61;

      .survey-wrapper {
        .left-panel {
          top: $num60;
          max-height: calc(#{$vh100} - #{$num60});
        }
      }
    }
  }
}

.survey-wrapper {
  .left-panel {
    .accordion {
      .accordion-item {
        h2 {
          .accordion-button {
            margin-bottom: $num0 !important;
            box-shadow: none;
            border-bottom: $num1 solid rgba($black, 0.13);
          }
        }
      }
    }
  }
}

.secondary-header .create-survey-flow {
  min-height: calc(#{$num60} - #{$num6});
}

.modal {
  z-index: 9999;
}

.modal-backdrop {
  z-index: 9999;
}

.non-edit-data {
  p.email-verified {
    padding-left: $num110;
    padding-top: $num0;
  }
}

.organization-primary {
  .non-edit-data {
    p {
      span {
        width: auto;
        padding-right: $num15;
      }
    }
  }
}

.secondary-header {
  .create-survey-flow {
    .left-menu {
      ul {
        position: inherit !important;
        min-width: inherit;
        font-size: inherit;
        color: inherit;
        background-color: inherit;
        background-clip: inherit;
        border: inherit;
        border-radius: inherit;
        transform: inherit !important;
      }
    }
  }
}

form .form-input-main .k-dropdown .k-dropdown-wrap,
.k-editor {
  border-color: $bordercolor;
}

div .k-list {
  font-size: $num12;
  border-radius: $num0;
}

.survey-wrapper .left-panel button svg {
  pointer-events: none;
}

.k-datetimepicker,
.k-dateinput {
  font-family: inherit;
}

.k-datetimepicker .k-picker-wrap {
  border-width: $num1;
  border-color: $bordercolor;
  border-style: solid;
}

.k-picker-wrap .k-dateinput .k-dateinput-wrap {
  color: #212529;
}

.k-input.form-control {
  height: $num45 !important;
  display: inline-flex;
}

.k-numerictextbox {
  &.text-center {
    .k-input-inner {
      text-align: inherit;
    }
  }
}

.k-input.form-control button,
.k-editor button {
  min-width: auto !important;
}

.k-datepicker .k-dateinput {
  height: auto !important;
}

.k-input.form-control:has(.k-dateinput.k-focus) {
  box-shadow: $num0 $num20 $num30 $num0 rgba($black, 0.08) !important;
  border: $num1 solid $secondaryColor !important;
}

.k-button-solid-base.k-selected {
  border-color: $secondaryColor;
  color: $white;
  background-color: $secondaryColor;
  background-image: linear-gradient(to right, #32ffff -50%, $secondaryColor 50%, #32ffff 150%);
}

.k-animation-container .k-list-item:hover,
.k-animation-container .k-list-item.k-selected,
.k-selected.k-list-optionlabel {
  background-color: $secondaryColor;
  background-image: linear-gradient(to right, #32ffff -50%, $secondaryColor 50%, #32ffff 150%);
  color: $white;
}

.k-popup.k-list-container {
  border-radius: $num0;
}

.k-list-item:focus,
.k-list-optionlabel:focus,
.k-list-item.k-focus,
.k-focus.k-list-optionlabel {
  box-shadow: none;
}

.k-editor .k-button-md.k-icon-button .k-button-icon {
  min-width: $rem1;
  min-height: $rem1;
}

.k-editor .k-input-value-text {
  font-size: $num14;
  line-height: 1.3;
}

.k-editor .k-rounded-md {
  border-radius: 0.25rem;
}

.k-editor .k-input-md .k-input-inner,
.k-picker-md .k-input-inner {
  padding: 0.275rem 0.75rem;
}

form .form-input-main .k-dropdown,
form .form-input-main .k-combobox {
  border: $num1 solid $bordercolor;
  border-radius: $num0;
  font-size: $num13;
  font-family: inherit;
  line-height: 1.5;
  color: inherit;
  font-weight: $medium;
}

.k-combobox:has(input:focus),
.k-combobox:has(button[aria-label="collapse combobox"]) {
  box-shadow: $num0 $num20 $num30 $num0 rgba($black, 0.08);
  border-color: $secondaryColor;
}

// Data request form
.defalut-middle-page {
  padding: $num100 $num0 $num40;
  width: $per100;
  min-height: calc(#{$vh100} - #{$num49});
}

.request-conformation .swal2-actions {
  flex-direction: row-reverse;
}

.personal-details-form {
  // max-width: calc(#{$num700} + #{$num250});
  // margin: auto;
  padding: $num0 $num20 $num30 !important;
  box-shadow: $num0 $num0 $num15 rgba($black, 0.05) !important;

  &+.personal-details-form {
    margin-top: $num50;
  }

  .datarequest-box-header {
    @include flex(flex, center, space-between);
    flex-direction: row-reverse;
    background-color: rgba($black, 0.03);
    border-bottom: $num1 solid rgba($black, 0.05);
    padding: $num20 $num20;
    margin: $num0 -#{$num20} $num30;
    min-height: $num80;

    h1 {
      font-size: $num24;
      line-height: 1;
      font-weight: $semibold;
    }

    .link-new-request {
      font-size: $num14;
      font-weight: $semibold;

      a,
      button {
        color: $secondaryColor;
        @include flex(flex, center, unset);
        column-gap: $num4;
        border: $num0;
        background-color: transparent;
        font-weight: inherit;
        padding: $num0;

        &:hover {
          color: inherit;
        }
      }
    }
  }

  h4 {
    font-size: $num18;
    margin-bottom: $num0;
  }

  .reason-text {
    margin-bottom: $num16;
  }

  ul:not(.k-radio-list) {
    padding: $num0;
    margin: $num12 $num0 $num0;
    list-style-type: none;

    li {
      padding: $num0 $num0 $num0 $num18;
      position: relative;

      &+li {
        margin-top: $num5;
      }

      &:before {
        font-family: "WebComponentsIcons";
        content: "\e018";
        position: absolute;
        left: $num0;
      }
    }
  }

  .current-request {
    @include flex(flex, center, space-between);
    flex-direction: row-reverse;
    margin-bottom: $num6;

    .current-status {
      font-weight: $semibold;
      padding: $num4 $num12;
      border-radius: var(--bs-border-radius-pill);
      font-size: $num12;
      box-shadow: $num0 0.125rem 0.25rem rgba($black, 0.075);
      border: $num1 solid rgba($bordercolor, 0.4);
      transition: 0.5s ease-in-out;
      color: $white;

      &.requested {
        background-color: $S-Requested;
      }

      &.inprogress {
        background-color: $S-Progress;
      }

      &.completed {
        background-color: $S-Completed;
      }

      &.canceled {
        background-color: $S-Canceled;
      }

      &.expired {
        color: inherit;
      }
    }
  }

  .status-date {
    font-weight: $semibold;
    text-align: center;
    font-size: $num14;
    margin-top: $num24;

    &.requested {
      color: $S-Requested;
    }

    &.inprogress {
      color: $S-Progress;
    }

    &.completed {
      color: $S-Completed;
    }

    &.canceled {
      color: $S-Canceled;
    }

    &+.status-date {
      margin-top: $num5;
    }
  }

  .request-info-btn {
    margin-top: $num24;

    p {
      margin-bottom: $num16;
      font-size: $num12;
      font-weight: $semibold;
      color: rgba($black, 0.5);
      @include flex(flex, flex-start, center);
      column-gap: $num4;
    }
  }

  .request-info {
    ul {
      margin: $num4 $num0 $num12;
    }
  }

  .history-timeline {
    padding: 24px;
    border-radius: $num16;

    .ht-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: $num4;

      h5 {
        margin-bottom: $num0;
        font-size: 18px;
      }

      a {
        color: $secondaryColor;
        font-weight: $semibold;

        &:hover {
          color: inherit;
        }
      }
    }

    .ht-inner {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      margin-top: 20px;

      .ht-status {
        position: relative;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        flex: $num0 $num0 auto;
        width: 33.33333333%;

        &:after {
          content: "";
          position: absolute;
          left: 50%;
          right: -100%;
          height: $num4;
          top: 13px;
          background-color: rgba(216, 216, 216, 0.5);
        }

        &:first-child {
          justify-content: flex-start;

          .ht-status-inner {
            text-align: start;
            padding-left: $num0;
          }

          &:after {
            left: $num0;
            right: -50%;
          }
        }

        &:last-child {
          justify-content: flex-end;

          .ht-status-inner {
            text-align: end;
            padding-right: $num0;
          }

          &:not(.canceled):after {
            left: unset;
            right: unset;
          }

          &:after {
            left: -100%;
            right: $num0;
          }
        }

        span {
          background: #fff;
          box-shadow: $num0 0.5rem 1rem rgba(0, 0, 0, 0.075);
          border-radius: 50%;
          font-weight: 600;
          font-size: 14px;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: $num1 solid $bordercolor;
          position: relative;
          z-index: 1;

          &.k-icon {
            display: none;
          }
        }

        &.current-status {
          span {
            border-color: $S-Requested;
            color: $S-Requested;
          }
        }

        &.passed-status {
          &:after {
            background-color: $S-Requested;
          }

          span {
            border-color: $S-Requested;
            background-color: $S-Requested;
            color: $white;
          }

          .k-icon {
            display: flex;
          }

          .ht-num {
            display: none;
          }
        }

        .ht-status-inner {
          text-align: center;
          display: block;
          position: relative;
          width: 100%;
          margin-top: $num8;
          font-size: $num12;
          padding: $num0 $num8;

          h6 {
            font-size: $num14;
            font-weight: $semibold;
          }
        }

        &.requested {
          h6 {
            color: $S-Requested;
          }
        }

        &.inprogress {
          h6 {
            color: $S-Progress;
          }
        }

        &.completed {
          h6 {
            color: $S-Completed;
          }
        }

        &.expired {
          h6 {
            color: inherit;
          }
        }

        &.canceled {
          h6 {
            color: $S-Canceled;
          }
        }
      }
    }

    &:nth-child(odd) {
      background-color: rgba($black, 0.04);
    }
  }

  .k-input-inner {
    padding: $num10 15px;
    height: 100%;
  }
}

.k-dropdownlist.form-select.k-picker-solid:hover,
.k-dropdownlist.form-select.k-picker-solid.k-hover,
.k-dropdownlist.form-select.k-picker-solid:focus,
.k-dropdownlist.form-select.k-picker-solid.k-focus {
  border-color: inherit;
  background-color: inherit;
}

.k-dropdownlist.form-select.k-picker .k-input-button {
  display: none;
}

.k-dropdownlist.form-select {
  padding: $num0;
}

.k-textarea.k-input.form-control {
  height: 100px !important;
  padding: $num0;
}

.category-selected {
  a {
    color: $secondaryColor;
    cursor: pointer;

    &:hover {
      color: inherit;
    }
  }
}

.survey-cate-tags {
  border-top: 1px solid $bordercolor;
  padding-top: 12px;

  span {
    color: #32c5ff;
    // &:hover {
    //   color: inherit;
    // }
  }

  .category-selected {
    span {
      margin-left: $num2;
    }
  }

  .tags-selected {
    margin-top: $num12;
    display: flex;
    align-items: flex-start;

    strong {
      min-height: $num32;
      display: flex;
      align-items: center;
    }

    .tags-list {
      display: inline-block;
      vertical-align: middle;
      margin-left: $num5;

      span {
        background-color: #fff;
        padding: $num4 $num12;
        border-radius: $num24;
        box-shadow: $num0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
        border: $num1 solid rgba($bordercolor, 0.6);
        margin: 2px;
        font-size: $num12;
        display: inline-block;
        vertical-align: top;
        font-weight: 500;
      }
    }
  }
}

.secondary-header {
  .survey-status {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: $num6;
      height: $num6;
      background-color: $white;
      top: calc(50% - 3px);
      left: $num10;
      border-radius: $per100;
    }

    span {
      color: $white !important;
      font-weight: $medium;
      padding: $num5 $num10 $num5 $num20;
      border-radius: $num3;
      box-shadow: $num0 $num1 $num3 $num0 rgba($black, 0.2);
      display: block;

      &.close-survey {
        background-color: $closeSurvey;
      }

      &.comming-survey {
        background-color: #17a2b8;
      }

      &.draft-survey {
        background-color: $draftSurvey;
      }

      &.open-survey {
        background-color: $activeSurvey;
      }
    }
  }
}

.survey-timings {
  font-size: $num13;

  span.start_date {
    color: $S-Completed;
    font-weight: $semibold;
    font-size: $num13;
  }

  span.end_date {
    color: #dc3545;
    font-weight: $semibold;
    padding-left: $num15;
    font-size: $num13;
  }
}

.org-info-popup .org-desc img {
  margin: 15px auto 15px;
  display: block;
}

.adduserform {
  @include flex(flex, center, flex-end);

  form {
    width: $num450;

    .form-control-sm {
      height: 40px;
    }

    .btn-grad.btn-sm {
      padding: 9px 15px;
    }
  }
}

.admin-box {
  box-shadow: $num0 $num0 $num8 rgba($black, 0.1);
  padding: $num24 $num16 $num42;
  border-radius: $num12;
  display: block;
  background-color: $white;
  position: relative;
  height: 100%;
  text-align: center;

  &:before {
    position: absolute;
    content: '';
    inset: 0px;
    bottom: 30px;
    transform: scaleY(0);
    transform-origin: left bottom;
    border-radius: 12px;
    transition: all 0.3s ease;
    background-color: #32c5ff;
  }

  &>* {
    position: relative;
    transition: all 0.3s ease;
  }

  .user-av {
    @include flex(inline-flex, center, center);
    width: $num70;
    height: $num70;
    background-color: $secondaryColor;
    color: $white;
    font-weight: $semibold;
    border-radius: $per50;
    margin-bottom: $num12;
    font-size: $num24;
    box-shadow: inset 0px 0px 20px rgba(0, 0, 0, 0.20);
  }

  h6 {
    margin-bottom: 16px;
    font-size: 18px;
  }

  p {
    text-align: left;
    display: flex;
    justify-content: space-between;
    padding: 8px 0;

    &+p {
      border-top: 1px dotted #e6e6e6;
    }
  }

  .admin-actions {
    @include flex(flex, center, space-between);
    column-gap: $num16;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 6px 16px;
    background-color: #f5f5f5;
    box-shadow: 0 0 4px rgba($black, 0.1);
    border-radius: 0 0 12px 12px;

    .remove-btn {
      border: $num0;
      padding: $num0;
      background-color: transparent;
      margin-left: $num8;
      color: $red;

      .k-icon {
        font-size: 18px;
      }
    }

    .admin-control {
      @include flex(flex, center, flex-end);
      font-size: $num12;
      color: $primaryColor;
      font-weight: $semibold;

      .k-switch {
        margin-left: 6px;
        width: 60px;
        color: $primaryColor;
        box-shadow: 0 0 4px rgba($black, 0.1);

        &.k-switch-md {
          .k-switch-track {
            span {
              display: inline;
              font-size: $num10;
            }

            .k-switch-label-on {
              left: 6px;
            }

            .k-switch-label-off {
              right: 6px;
            }
          }
        }
      }
    }
  }

  a {
    color: $secondaryColor;

    &:hover {
      color: inherit;
    }
  }

  &:hover {
    // box-shadow: $num0 $num0 $num0 $num4 $secondaryColor;
    color: #fff;

    &:before {
      transform: scaleY(1);
    }

    .user-av {
      background-color: #fff;
      color: #32c5ff;
    }

    p+p {
      border-color: rgba($white, 0.4);
    }

    a {
      color: $white;

      &:hover {
        color: $primaryColor;
      }
    }
  }
}

.formwithcontent {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .formcontent {
    width: 100%;
    padding-right: 60px;
    padding-left: 60px;
    text-align: left;

    h1 {
      font-size: 36px;
      margin-bottom: $num16;
      color: $secondaryColor;
    }

    h2 {
      font-size: 18px;
      margin-bottom: $num8;
    }

    p {
      margin-bottom: $num16;
    }

    ul {
      list-style-type: none;
      margin: $num10 $num0;
      padding-left: $num0;

      li {
        padding-left: 20px;
        position: relative;

        &::before {
          color: $secondaryColor;
          content: "\e044";
          font-family: WebComponentsIcons;
          left: $num0;
          position: absolute;
          top: $num0;
        }
      }
    }
  }

  .requestregister-form {
    max-width: #{$num500 + $num50};
  }
}


.survey-description {
  margin-bottom: 8px;
  background-color: $white;
  overflow: hidden;
  transition: all 0.3s;

  &.show {
    // max-height: 65vh;
    padding: $num10 0;
    // overflow-y: scroll;
  }

  &.hide {
    height: 0;
  }

  p {
    font-size: $num12;
    margin: 0 0 0em;

    strong {
      font-weight: bolder;
    }

    em {
      font-style: italic;
    }

    +p {
      margin-top: $num10;
    }

    &>p:empty {
      min-height: 18px;
    }
  }

  img {
    display: block;
    margin: 10px auto 0px;
    width: 25%;
  }

  a {
    color: #32c5ff;
    cursor: pointer;
    text-decoration: underline;

    span {
      color: #32c5ff !important;
    }
  }

  h1,
  img {
    display: none;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 $num5 #ffffff;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #ddd;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $grey;
  }

  .surveyStatus {
    color: #fff;
    font-size: 12px;
    position: relative;
    padding: 5px 5px 5px 15px;
    letter-spacing: 0.5px;
    display: inline;

    span {
      content: "";
      position: absolute;
      top: 11px;
      left: 6px;
      background: #fff;
      width: 6px;
      height: 6px;
      border-radius: 6px;
    }
  }

  .survey-timings {
    p {
      font-size: 13px;
      display: flex;

      span.start_date {
        color: #28a745;
        font-size: 13px;
        font-weight: 600;
      }

      span.end_date {
        color: #dc3545;
        font-size: 13px;
        font-weight: 600;
        padding-left: 15px;
      }
    }
  }
}

.survey-toggle {
  width: $num150;
  // background-color: #fff;
  height: $num20;
  //  border-radius: 0 0 $num10 $num10;
  position: relative;
  cursor: pointer;
  text-align: right;
  transition: all 0.3s;

  &.rotate {
    //  border: 1px solid #ddd;
    //  border-top: none;
    top: -$num1;

    svg {
      transform: rotate(180deg);
    }
  }

  .toggle_text {
    color: $secondaryColor;

  }

  .toggle_text:hover {
    color: #3d3d3c;
  }


  svg {
    position: relative;
    bottom: $num0;
    left: $num3;
    transition: all 0.3s;
  }

}

.pre-loading .pre-yes {
  color: transparent;
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%);
  background-size: 400%;
  animation: pre-loading 1.2s ease-in-out infinite;
}

@keyframes pre-loading {
  0% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0 50%;
  }
}


.forcontentPadding {
  padding: 30px;
}

.changeOrderBtn {
  display: flex;
  justify-content: flex-end;
}

.changeOrderBtn button {
  border: 1px solid #32c5ff;
  background-color: transparent;
  color: #32c5ff;
  padding: 4px 8px;
  transition: 0.3s;
}

.changeOrderBtn button:hover {
  background-color: #3d3d3c;
  border-color: #3d3d3c;
  color: #fff;
}



.padding-login-main {
  padding: 0px;

  @media (max-width:650px) {
    padding-bottom: 16px;
  }
}


/* Anand CSS */
.k-datetime-buttongroup {
  padding: 0px !important;

  button {
    width: 100%;
    padding: 3px 0px;
  }
}

.k-datetime-selector {
  height: 250px;

  .k-calendar {
    height: 265px;

    .k-calendar-view {
      height: 265px;
      margin: 0;
      padding: 0 10px;

      .k-calendar-header {
        padding: 0 15px;

        .k-button {
          padding: 0 10px;
        }

        .k-calendar-nav {
          .k-button {
            padding: 5px 15px;
          }
        }
      }
    }
  }
}

.k-datetime-footer {
  margin-top: 10px;
}

.report_flag_popup {
  .k-window-content {
    padding: 0;
  }

  .report_flag_popup_title {
    padding: 10px 35px 10px 15px;
    border-bottom: 1px solid #dee2e6;

    .k-button-md.k-icon-button {
      position: absolute;
      right: 8px;
      top: 10px;
      padding: 0;
      color: inherit;
      opacity: 0.5;

      &:hover {
        opacity: 1;

        &::before {
          opacity: 0;
        }
      }
    }

    h4 {
      font-size: 20px;
      margin-bottom: 3px;
    }
  }

  .report_flag_popup_desc {
    padding: 20px;
  }

  &::placeholder {
    font-size: $num12;
    letter-spacing: $num0 !important;
    font-weight: $medium;
    color: $primaryColor;
    font-family: $primaryFontFamily;
  }
}

.personal-details-form .add-question-button ul:not(.k-radio-list) li:before {
    font-family: "WebComponentsIcons";
    content: "";
    position: absolute;
    left: 0;
}

.personal-details-form .question-type-box ul:not(.k-radio-list) li:before {
  font-family: "WebComponentsIcons";
  content: "";
  position: absolute;
  left: 0;
}

/* Anand CSS */